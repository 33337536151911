import { withTranslation } from "react-i18next";
import * as React from "react";
import { Checkbox, Dropdown, Label, Option } from "@fluentui/react-components";
import { t } from "i18next";
import { Sheet } from "../../models/excel.models";
import { DefaultButton } from "@fluentui/react";
/* global Excel  */

const Clears = () => {
  const ClearFilter = "clearFilter";
  const ClearTabColor = "clearTabColor";
  const ClearCellBackgroudColor = "clearCellBackgroudColor";
  const ClearCellFontFormat = "clearCellFontFormat";
  const ClearImages = "clearImages";
  const [sheets, setSheets] = React.useState([]);
  const [selectedSheets, setSelectedSheets] = React.useState([]);
  const [clearOptions, setClearOptionsState] = React.useState([
    { id: ClearFilter, label: t(ClearFilter), isChecked: false },
    { id: ClearTabColor, label: t(ClearTabColor), isChecked: false },
    { id: ClearCellBackgroudColor, label: t(ClearCellBackgroudColor), isChecked: false },
    { id: ClearCellFontFormat, label: t(ClearCellFontFormat), isChecked: false },
    { id: ClearImages, label: t(ClearImages), isChecked: false },
  ]);
  React.useEffect(() => {
    const initSheets = async () => {
      let items: Sheet[] = [];
      items.push({
        name: "All",
      });
      await Excel.run(async (context) => {
        let sheets: Excel.WorksheetCollection = context.workbook.worksheets;
        sheets.load("items/name, items/tabColor");
        await context.sync();
        sheets.items.forEach((s) => {
          items.push({
            name: s.name,
            tabColor: s.tabColor,
          });
        });
      });
      setSheets(items);
    };
    initSheets().catch(console.error);
  }, []);
  const allChecked = () =>
    clearOptions.filter((c) => c.isChecked).length == 0
      ? false
      : clearOptions.filter((c) => c.isChecked).length == clearOptions.length
      ? true
      : "mixed";
  const clearOptionAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClearOptions = clearOptions.map((clearOption) => {
      clearOption.isChecked = event.target.checked;
      return clearOption;
    });
    setClearOptionsState(newClearOptions);
  };
  const onSheetsSelectedChange = (event, data) => {
    let isAllClick = data.optionValue === "All";
    let isAllSelected = data.selectedOptions.includes("All");
    if (isAllClick) {
      if (isAllSelected) {
        setSelectedSheets(sheets.map((sheet) => sheet.name));
      } else{
        setSelectedSheets([]);
      }
    } else {
      setSelectedSheets(data.selectedOptions.filter((s) => s != "All"));
    }
  };
  const clearOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClearOptions = clearOptions.map((clearOption) => {
      if (clearOption.id == event.target.id) {
        clearOption.isChecked = event.target.checked;
      }
      return clearOption;
    });
    setClearOptionsState(newClearOptions);
  };
  const Clears = async () => {
    await Excel.run(async (context) => {
      let sheets = context.workbook.worksheets;
      sheets.load("items/tabColor,items/name,items/shapes/items/type");
      await context.sync();
      console.log(selectedSheets);
      sheets.items
        .filter((sheet) => selectedSheets.includes(sheet.name))
        .forEach((sheet) => {
          // Show all data
          if (clearOptions.find((c) => c.id == ClearFilter).isChecked) {
            sheet.autoFilter.clearCriteria();
          }
          // set tab color to no color
          if (clearOptions.find((c) => c.id == ClearTabColor).isChecked) {
            sheet.tabColor = "";
          }
          // set cell fill to no fill
          if (clearOptions.find((c) => c.id == ClearCellBackgroudColor).isChecked) {
            const rangeFill = sheet.getRange().format.fill;
            rangeFill.clear();
          }
          // rest cell font to no color
          if (clearOptions.find((c) => c.id == ClearCellFontFormat).isChecked) {
            const rangeFont = sheet.getRange().format.font;
            rangeFont.color = "#000000";
          }
          // clear images
          if (clearOptions.find((c) => c.id == ClearImages).isChecked) {
            let shapes = sheet.shapes;
            shapes.items.forEach((shape) => {
              console.log(shape.type);
              if (shape.type == Excel.ShapeType.image) {
                shape.delete();
              }
            });
          }
        });
      await context.sync();
    });
  };
  return (
    <div>
      <div>
        <Label>{t("SelectWorkSheets")}</Label>
      </div>
      <div>
        <Dropdown multiselect placeholder={t("SelectWorkSheets")} selectedOptions={selectedSheets} onOptionSelect={onSheetsSelectedChange}>
          {sheets.map((sheet) => (
            <Option key={sheet.name}>{sheet.name}</Option>
          ))}
        </Dropdown>
      </div>
      <div>
        <Checkbox label={t("CheckAll")} checked={allChecked()} onChange={clearOptionAllChange} />
      </div>
      <div>
        {clearOptions.map((clearOption) => (
          <div key={clearOption.id}>
            <Checkbox
              id={clearOption.id}
              checked={clearOption.isChecked}
              onChange={clearOptionChange}
              label={clearOption.label}
            />
          </div>
        ))}
      </div>
      <DefaultButton onClick={Clears}>{t("confirm")}</DefaultButton>
    </div>
  );
};

export default withTranslation()(Clears);
