import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { AppProps } from "../App";
import { messageObserver } from "../redux/observers";
import { store } from "../redux/store";
import Settings from "../settings/Settings";

const WordApp = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);

    return (
      <Routes>   
        <Route path="word">
            <Route path="" element={<Settings />} />   
        </Route>                        
      </Routes>
    );
  };

export default WordApp;