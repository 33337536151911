import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { LoadingProps } from "../controls/loading/loading";
import { useAppDispatch } from "./hooks";
import { RootState } from "./store";

interface CommonState {
    message: string;
    adTrigger: number;
    userTrigger: number;
    loginTrigger: number;
    isInit: boolean;
    loadingState: LoadingProps;
}
const initialState: CommonState = {
    message: "",
    adTrigger: 0,
    userTrigger: 0,
    loginTrigger: 0,
    isInit: false,
    loadingState:{
        shown: false,
        label: t('loading'),
    }
}
export const commonSlice = createSlice({
    name: 'common',
    reducers: {
        setMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload
        },
        clearMessage: (state) => {
            state.message = ""
        },
        triggerAd: (state) =>{
            state.adTrigger += 1;
        },
        triggerUser: (state) => {
            state.userTrigger +=1;
        },
        triggerLogin: (state) => {
            state.loginTrigger +=1;
        },
        setIsInit: (state) =>{
            state.isInit = true;
        },
        triggerLoading:(state, action: PayloadAction<LoadingProps>) =>{
            state.loadingState = Object.assign(state.loadingState, action.payload)
        }
    },
    initialState: initialState
})

export const { setMessage, clearMessage, triggerAd, triggerUser, triggerLogin, setIsInit, triggerLoading} = commonSlice.actions

export default commonSlice.reducer;
