import { AppConsts } from "../shared/appconsts";
import * as moment from "moment";
import * as http from "./http";
import { MeekouResponse } from "./common.model";
import { IHttpResponse } from "./http";

export class MeekouApi {
  private baseUrl: string;
  private accessToken: string;
  /**
   *
   */
  constructor() {
    this.baseUrl = AppConsts.remoteServiceBaseUrl;
  }
  async loginByMiniQrCode() {
    this.accessToken =
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJhZG1pbkBhc3BuZXRib2lsZXJwbGF0ZS5jb20iLCJBc3BOZXQuSWRlbnRpdHkuU2VjdXJpdHlTdGFtcCI6IjJjOTdlNGFjLWM4NTItNmUzNS0yZWRmLTNhMDhhNzkyNGYxOCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwic3ViIjoiMSIsImp0aSI6ImI1MDQ1ZDUwLTAxYTktNDI0OC1iZGUyLWFmODQ3ZmRiYmQ3MCIsImlhdCI6MTY3NDg3MDg3MCwibmJmIjoxNjc0ODcwODcwLCJleHAiOjE2NzQ5NTcyNzAsImlzcyI6Ik1hbmdvIiwiYXVkIjoiTWFuZ28ifQ.OnUbWCfMiEo-h8vGNDsX1iVmnjUUAMLcS5ALF9xfXaU";
  }
  /**
   * @param body (optional)
   * @return Success
   */
  async loginByCode(body: LoginByCodeInput | undefined): Promise<LoginOutput> {
    let url_: string = this.baseUrl + "/api/services/app/User/LoginByCode";
    url_ = url_.replace(/[?&]$/, "");
    const response: IHttpResponse<MeekouResponse<LoginOutput>> = await http.post<MeekouResponse<LoginOutput>>(
      url_,
      body
    );
    if (response.parsedBody.success) {
      return response.parsedBody.result as LoginOutput;
    }
    return null;
  }
  /**
   * get user info by id
   * @param userId userid
   * @returns
   */
  async getUserInfoById(userId: number | undefined): Promise<UserInfoDto> {
    let url_: string = this.baseUrl + "/api/services/app/User/GetUserInfoById?";
    if (userId === null) throw new Error("The parameter 'userId' cannot be null.");
    else if (userId !== undefined) url_ += "userId=" + encodeURIComponent("" + userId) + "&";
    url_ = url_.replace(/[?&]$/, "");
    if (!this.accessToken) {
      await this.loginByMiniQrCode();
    }
    const response: IHttpResponse<MeekouResponse<UserInfoDto>> = await http.get<MeekouResponse<UserInfoDto>>(
      url_,
      this.accessToken
    );
    if (response.parsedBody.success) {
      return response.parsedBody.result as UserInfoDto;
    }
    return null;
  }
}

const meekouApi = new MeekouApi();
export default meekouApi;

export class LoginByCodeInput {
  inputCode: string | undefined;
  redirect: string | undefined;
  public constructor(init?: Partial<LoginByCodeInput>) {
    Object.assign(this, init);
  }
}

export class LoginOutput {
  accessToken: string | undefined;
  user: UserDto;
  qrCodeOutput: QRCodeOutput;
}

export class UserDto {
  id: number;
  userName: string;
  name: string;
  surname: string;
  emailAddress: string;
  openId: string | undefined;
  isActive: boolean;
  fullName: string | undefined;
  lastLoginTime: moment.Moment | undefined;
  creationTime: moment.Moment;
  roleNames: string[] | undefined;
}

export class QRCodeOutput {
  qrCodeLink: string | undefined;
  content: string | undefined;
  outputCode: string | undefined;
}

export class ProductInfoDto implements IProductInfoDto {
  id: number;
  shortName: string | undefined;
  name: string | undefined;
  expireDate: moment.Moment;
  activationType: string | undefined;

  constructor(data?: IProductInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.shortName = _data["shortName"];
      this.name = _data["name"];
      this.expireDate = _data["expireDate"] ? moment(_data["expireDate"].toString()) : <any>undefined;
      this.activationType = _data["activationType"];
    }
  }

  static fromJS(data: any): ProductInfoDto {
    data = typeof data === "object" ? data : {};
    let result = new ProductInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["id"] = this.id;
    data["shortName"] = this.shortName;
    data["name"] = this.name;
    data["expireDate"] = this.expireDate ? this.expireDate.toISOString() : <any>undefined;
    data["activationType"] = this.activationType;
    return data;
  }

  clone(): ProductInfoDto {
    const json = this.toJSON();
    let result = new ProductInfoDto();
    result.init(json);
    return result;
  }
}

export interface IProductInfoDto {
  id: number;
  shortName: string | undefined;
  name: string | undefined;
  expireDate: moment.Moment;
  activationType: string | undefined;
}

export class UserInfoDto implements IUserInfoDto {
  id: number;
  avatarUrl: string | undefined;
  nickName: string | undefined;
  phoneNumber: string | undefined;
  restIntegrals: number;
  isSign: boolean;
  viewVideoCount: number;
  availableProducts: ProductInfoDto[] | undefined;

  constructor(data?: IUserInfoDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.avatarUrl = _data["avatarUrl"];
      this.nickName = _data["nickName"];
      this.phoneNumber = _data["phoneNumber"];
      this.restIntegrals = _data["restIntegrals"];
      this.isSign = _data["isSign"];
      this.viewVideoCount = _data["viewVideoCount"];
      if (Array.isArray(_data["availableProducts"])) {
        this.availableProducts = [] as any;
        for (let item of _data["availableProducts"]) this.availableProducts.push(ProductInfoDto.fromJS(item));
      }
    }
  }

  static fromJS(data: any): UserInfoDto {
    data = typeof data === "object" ? data : {};
    let result = new UserInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["id"] = this.id;
    data["avatarUrl"] = this.avatarUrl;
    data["nickName"] = this.nickName;
    data["phoneNumber"] = this.phoneNumber;
    data["restIntegrals"] = this.restIntegrals;
    data["isSign"] = this.isSign;
    data["viewVideoCount"] = this.viewVideoCount;
    if (Array.isArray(this.availableProducts)) {
      data["availableProducts"] = [];
      for (let item of this.availableProducts) data["availableProducts"].push(item.toJSON());
    }
    return data;
  }

  clone(): UserInfoDto {
    const json = this.toJSON();
    let result = new UserInfoDto();
    result.init(json);
    return result;
  }
}

export interface IUserInfoDto {
  id: number;
  avatarUrl: string | undefined;
  nickName: string | undefined;
  phoneNumber: string | undefined;
  restIntegrals: number;
  isSign: boolean;
  viewVideoCount: number;
  availableProducts: ProductInfoDto[] | undefined;
}
