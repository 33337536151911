import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { AppProps } from "../App";
import { messageObserver } from "../redux/observers";
import { store } from "../redux/store";
import Settings from "../settings/Settings";
import Export from "./data/Export";
import Import from "./data/Import";
import LinkDownload from "./data/LinkDownload";
import PasteToVisiable from "./data/PasteToVisiable";
import BatchFilter from "./manager/BatchFilter";
import Clears from "./manager/Clears";
import MoveColumns from "./manager/moveColumns";
import Spotlight from "./manager/Spotlight";
import Toc from "./manager/Toc";
import CellOperation from "./operations/CellOperation";
import FindReplace from "./operations/FindReplace";
import RangeEditForm from "./operations/RangeEditForm";

const ExcelApp = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);

    return (
      <Routes>   
        <Route path="excel">
            <Route path="operations/rangeeditform" element={<RangeEditForm />} />  
            <Route path="data/import" element={<Import />} />
            <Route path="data/export" element={<Export />} />
            <Route path="data/pastetovisiable" element={<PasteToVisiable />} />            
            <Route path="data/linkdownload" element={<LinkDownload />} /> 
            <Route path="manager/toc" element={<Toc />} />
            <Route path="manager/spotlight" element={<Spotlight />} />
            <Route path="manager/movecolumns" element={<MoveColumns />} />
            <Route path="manager/batchfilter" element={<BatchFilter />} />
            <Route path="manager/clears" element={<Clears />} /> 
            <Route path="operations/findreplace" element={<FindReplace />} />  
            <Route path="operations/celloperation" element={<CellOperation />} />  
        </Route>                        
      </Routes>
    );
  };

export default ExcelApp;