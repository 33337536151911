import { t } from "i18next";
import moneyUpperCase from 'money-uppercase'
import * as chinesenum from "chinesenum";
import { FileType } from "../models/fileType";

class UtilsHelper {
  /**
   * convert image file to base64 string
   * convert json, xml, csv, txt file to array with first row is header (use for export to excel)
   * @param file file to convert
   * @returns object with file type and file content like base64 string or array data
   */
  fileToContent = async (file: File, fileType: FileType = FileType.JSON): Promise<any> => {
    try {
      let fileContent: any;
      if (fileType === FileType.Image) {
        fileContent = await this.fileToBase64(file);
      } else if (fileType === FileType.JSON){
        fileContent = await this.fileToJson(file);
        return [
          FileType.JSON,
          fileContent
        ];
      }
      return [
        fileType,
        fileContent
      ];
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  }
  /**
   * convert file to base64 string
   * @param file file to convert
   * @returns base64 string
   */
  fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result as string);
      };
      reader.onerror = (event) => {
        reject(event);
      };
      reader.readAsDataURL(file);
    });
  }
  /**
   * convert file to json string
   * @param file file to convert
   * @returns json string
   */
  fileToJson = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result as string);
      };
      reader.onerror = (event) => {
        reject(event);
      };
      reader.readAsText(file);
    });
  }

  /**
   * convert json string to array with first row is header
   * @param content json string content
   * @returns array data
   */
  jsonToArray = (content: string): any[] => {
    var data: any = JSON.parse(content);
    var table: any[] = [];
    // write headers
    var headers: any[] = Object.getOwnPropertyNames(data[0]);
    table.push(headers);
    // write rows
    data.forEach((row) => {
      table.push(Object.values(row));
    });
    return table;
  }
  /**
   * get array data from clipboard
   * @returns array data from clipboard
   * catch error if clipboard is empty
   */
  getDataFromClipboard = async (): Promise<any[]> => {
    let clipboardData: unknown[][] = [];
    try {
      const clipboardContents = await navigator.clipboard.read();
      // No valid data on clipboard
      if (clipboardContents.length === 0) {
        throw new Error(t("NoDataInClipboard"))
      }
      for (const item of clipboardContents) {
        const blob = await item.getType("text/html");
        const html = await blob.text();
        console.log(html);
        clipboardData = [...clipboardData, ... this.convertTableHtmlToArray(html)];
      }
    } catch (error) {
      throw new Error(error.message);
    }
    return clipboardData;
  }
  /**
   * read html format data from clipboard
   * @returns clipboard html format content
   */
  readHtmlFromClipboard = async () =>{    
    try {
      const [clipboardItem] = await navigator.clipboard.read({
        unsanitized: ['text/html'],
      });
      const htmlBlob = await clipboardItem.getType('text/html');
      const html = await htmlBlob.text();   
      return html;   
    } catch (error) {
      throw new Error(error.message);
    }    
    return "";
  }
  /**
   * write html content to clipboard
   * @param htmlContent content with html format
   */
  writeHtmlToClipboard = async (htmlContent: string): Promise<void> => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({
      'text/html': blob
    });

    navigator.clipboard.write([clipboardItem]).then(() => {
      console.log('HTML content copied to clipboard successfully');
    }, (err) => {
      console.error('Failed to copy HTML content: ', err);
    });
  }
  /**
   * convert html table to array
   * @param html table html string
   */
  convertTableHtmlToArray = (tableHtml: string): any[][] => {
    const table = document.createElement("table");
    table.innerHTML = tableHtml;
    const rows = table.querySelectorAll("tr");
    const data: any[][] = [];
    rows.forEach((row) => {
      const cols = row.querySelectorAll("td");
      const rowData: any[] = [];
      cols.forEach((col) => {
        rowData.push(col.innerHTML);
      });
      data.push(rowData);
    });
    return data;
  }
  /**
   * create new guid which length is 32
   * @parameter length guid length
   * @returns guid string
   */
  newGuid = (length: number = 32): string => {
    let guid = "";
    for (let i = 1; i <= length; i++) {
      const n = Math.floor(Math.random() * 16.0).toString(16);
      guid += n;
    }
    return guid;
  }
  /**   
   * create a function to delay execution
   * @param ms delay time in milliseconds
   * @returns function to delay execution
   */
  sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  
  /**
   * convert dataURI to blob
   */
  dataURItoBlob = (dataURI: string):Blob =>{
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  }
  /**
   * download file by datauri
   * @param dataURI file data uri
   * @param fileName file name
   */
  downloadDataURI = (dataURI: string, fileName:string = "") =>{
    const downloadLink = document.createElement("a");
    downloadLink.href = dataURI;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  /**
   * convert 1-20 to circled numbers
   * @param num to format value
   * @returns circled number string
   */
  toCircledNumber = (num: number = 0) =>{
    const circledNumbers: { [key: number]: string } = {
      0: '\u24EA', 1: '\u2460', 2: '\u2461', 3: '\u2462', 4: '\u2463',
      5: '\u2464', 6: '\u2465', 7: '\u2466', 8: '\u2467', 9: '\u2468',
      10: '\u2469', 11: '\u246A', 12: '\u246B', 13: '\u246C', 14: '\u246D',
      15: '\u246E', 16: '\u246F', 17: '\u2470', 18: '\u2471', 19: '\u2472',
      20: '\u2473'
    };
    if (num >= 0 && num <= 20) {
      return circledNumbers[num];
    } else {
      return ("Only 0-20 are supported, for bigger, try with Image function to show circled number image");
    }
  }
  /**
   * rmb format
   * @param num money number
   * @returns money chinese string
   */
  toMoney = (num: number) =>{
    return moneyUpperCase(num);
  }
  toChinese = (num:number | string | bigint = 0, option?: any) =>{
    return chinesenum.numToChinanumerals(num, option);
  }
  /**
   * get max inter and fraction for array number
   * @param arr array with number value
   * @returns get max interer and max fraction from array
   */
  getMaxIntegerAndFractionLengths = (arr: (number | string)[][]): { integerPart: number, fractionPart: number } => {
    let maxIntegerPart = 0;
    let maxFractionPart = 0;

    // Flatten the nested array
    const flattened = arr.flat();

    // Process each number
    for (const num of flattened) {
        // Convert number to string if it's not already a string
        const numStr = num.toString();
        const [integer, fraction] = numStr.split('.');

        if (integer.length > maxIntegerPart) {
            maxIntegerPart = integer.length;
        }

        // If there is a decimal part, check its length
        if (fraction && fraction.length > maxFractionPart) {
            maxFractionPart = fraction.length;
        }
    }

    return {
        integerPart: maxIntegerPart,
        fractionPart: maxFractionPart
    };
  }
  /**
   * generate mask value based on input integer and fraction length
   * @param maxLengths length for different number part
   * @returns return mask value for number
   */
  generateMask(maxLengths: { integerPart: number, fractionPart: number }): string {
    const integerMask = '?'.repeat(maxLengths.integerPart);
    const fractionMask = '?'.repeat(maxLengths.fractionPart);

    return fractionMask ? `${integerMask}.${fractionMask}` : integerMask;
}
}

export const utilsHelper = new UtilsHelper();
