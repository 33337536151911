/* eslint-disable no-unused-vars */
import { Dialog, DialogBody, DialogSurface, makeStyles, Spinner } from "@fluentui/react-components";
import * as React from "react";
import { t } from "i18next";

const useStyles = makeStyles({
  root: {

  },
  dialogBody:{
    display:'flex',
    justifyContent: 'center'
  }
});

export interface LoadingProps {
  label?: string;
  shown?: boolean;
}
const Loading = (props: LoadingProps) => {
  const styles = useStyles();
  return (
    <>
        <Dialog open={props.shown}>
          <DialogSurface>
            <DialogBody className={styles.dialogBody}>
              <Spinner label={props.label} />
            </DialogBody>
          </DialogSurface>
        </Dialog>
    </>
  );
};

export default Loading;
