import { buildColumns, DetailsList, IColumn, IDragDropEvents, Label, MarqueeSelection, Selection, SelectionMode} from "@fluentui/react";
import { Radio, RadioGroup, RadioGroupOnChangeData } from "@fluentui/react-components";
import { t } from "i18next";
import React from "react";
import MeekouLayout from "../../MeekouLayout";
import { excelHelper } from "../../services/excel.helper";

interface IColumnItem{
    columnName: string;
    columnHeader: string;
}
enum MoveColumnType{
    MoveColumn = "MoveColumn",
    SwitchColumn = "SwitchColumn"
}
const MoveColumns = ()=>{
    const [moveColumnType, setMoveColumnType] = React.useState(MoveColumnType.MoveColumn);
    const [columnItems, setColumnItems] = React.useState([]);
    const [draggedItem, setDraggedItem] = React.useState(undefined as IColumnItem);
    const [draggedIndex, setDraggedIndex] = React.useState(-1);
    const [selection] = React.useState<Selection>(() => {
        return new Selection({
          getKey: (item: any, index?: number): string => {
            return item && item.id ? item.id : -1 * (index ?? 0) + "";
          }
        });
      });
    const dragColumns: IColumn[] = [
        {
            key: "columnName",
            name: "columnName",
            fieldName: "columnName",
            minWidth: 50,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "columnHeader",
            name: "columnHeader",
            fieldName: "columnHeader",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        }
    ]
    const RefreshColumns = async () => {
        const [columnIndexs, columnNames, headers] = await excelHelper.getUsedRangeColumnIndexsNamesAndFirstRowValues();
        setColumnItems([...columnNames.map((columnName, index) => ({
            columnName,
            columnHeader: headers[index]
        } as IColumnItem))]);
    };
    React.useEffect(() => {
        RefreshColumns();
    }, []);
    const InsertBeforeItem = async (item: IColumnItem): Promise<void> => {
        const draggedItems = selection.isIndexSelected(draggedIndex)
          ? (selection.getSelection() as IColumnItem[])
          : [draggedItem!];
    
        const insertIndex = columnItems.indexOf(item);
        const items = columnItems.filter(itm => draggedItems.indexOf(itm) === -1);
    
        items.splice(insertIndex, 0, ...draggedItems);
        switch (moveColumnType) {
            case MoveColumnType.MoveColumn:
                await excelHelper.moveColumns(draggedItems.map(draggedItem => draggedItem.columnName), item.columnName);
                break;
            case MoveColumnType.SwitchColumn:
                await excelHelper.switchColumn(draggedItems[0].columnName, item.columnName);
            default:
                break;
        }
        await RefreshColumns();
      }
    const MoveColumnTypeChange = (ev: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) =>{
        setMoveColumnType(data.value as MoveColumnType);
    }
    const ColumMove = (): IDragDropEvents =>{
        return {
            canDrop: (dropContext?: any, dragContext?: any) => {
                return true;
            },
            canDrag: (item?: any) => { return true; },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {
                    InsertBeforeItem(item);
                }
              },
              onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                setDraggedItem(item);
                setDraggedIndex(itemIndex!);
              },
              onDragEnd: (item?: any, event?: DragEvent) => {
                setDraggedItem(undefined);
                setDraggedIndex(-1);
              },
          };
    }
    return (
        <MeekouLayout>
            <div>
                <Label id="MoveColumnType">{t("moveColumnType")}</Label>
                <RadioGroup aria-labelledby="MoveColumnType" value={moveColumnType} onChange={MoveColumnTypeChange}>
                    <Radio value={MoveColumnType.MoveColumn} label={t("MoveColumn")} />
                    <Radio value={MoveColumnType.SwitchColumn} label={t("SwitchColumn")} />
                </RadioGroup>
            </div>
            <MarqueeSelection selection={selection}>
                <DetailsList
                    items={columnItems}
                    selectionMode={SelectionMode.single}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    columns={dragColumns}
                    dragDropEvents={ColumMove()}
                />
            </MarqueeSelection>
        </MeekouLayout>
    );
}

export default MoveColumns;