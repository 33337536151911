import * as React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { messageObserver } from "./redux/observers";
import { store } from "./redux/store";
import WordApp from "./word/WordApp";
import ExcelApp from "./excel/ExcelApp";
import CommonApp from "./common/CommonApp";
import Home from "./home/Home";
import ToolApp from "./tool/ToolApp";
import HomeApp from "./home/HomeApp";
import DialogApp from "./dialog/DialogApp";

export interface AppProps {
    title: string;
    isOfficeInitialized: boolean;
    hostType: Office.HostType;
}
const App = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);
    const HashRouterPage = () => {
      return (
        <HashRouter>
          <CommonApp {...props}/>
          {props.hostType === Office.HostType.Excel && <ExcelApp {...props}/>}
          {props.hostType === Office.HostType.Word && <WordApp {...props}/>}
        </HashRouter>
      );
    };
    return (
      <>
        <HomeApp {...props}/>
        <ToolApp {...props}/>
        <DialogApp {...props}/>
        <HashRouterPage />
      </>
    );
  };
  
  export default App;