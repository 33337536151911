import * as React from "react";
import * as ReactDOM from "react-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkToc from "remark-toc";
import remarkSlug from 'remark-slug';
import meekou from "./meekou.md";
import remarkLinkDownload from "../remarkPlugins/remarkLinkDownload";
import DownloadAbleLink from "../controls/downloadAbleLink/DownloadAbleLink";

const Home = () =>{
    return (
        <>
            <ReactMarkdown 
                children={meekou} 
                remarkPlugins={[remarkGfm, remarkSlug, [remarkToc, {heading: "目录"}]]} 
                components = {{
                    a: DownloadAbleLink
                }}
            />
        </>
    )
}

export default Home;
