/* global Excel, Office  */

import { utilsHelper } from "./utilsHelper";

class ExcelHelper {
  async promptForRangeBindingId(): Promise<string> {
    return new Promise((resolve, reject) => {
      const handleResult = (result: Office.AsyncResult<Office.Binding>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value.id);
        } else {
          reject(result.error.message);
        }
      };
      Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, handleResult);
    });
  }
  async getDataByBindingId(bindingId: string): Promise<[string, any[][]]> {
    return new Promise((resolve, reject) => {
      Excel.run(async (ctx) => {
        const binding = ctx.workbook.bindings.getItem(bindingId);
        const range = binding.getRange();
        range.load("address,values");
        await ctx.sync();
        return resolve([range.address.replace(/'/g, ''), range.values]);
      })
        .catch((error) => reject(error))
        .finally(() => {
          return Office.context.document.bindings.releaseByIdAsync(bindingId);
        });
    });
  }
  /**
   * check whether source range is in target range
   * @param source range to check
   * @param target target range
   * @returns 
   */
  async isInRange(source: string, target: string): Promise<boolean>{
    let result = false;
    source = source.replace(/'/g, '');
    target = target.replace(/'/g, '');
    const [sourceWorksheetName, sourceRangeAddress] = source.split(/!(.*)/);
    const [targetWorksheetName, targetRangeAddress] = target.split(/!(.*)/);
    await Excel.run(async (context) => {    
      let sourceRange = context.workbook.worksheets.getItem(sourceWorksheetName).getRange(sourceRangeAddress);
      let targetRange = context.workbook.worksheets.getItem(targetWorksheetName).getRange(targetRangeAddress);
      const range = targetRange.getBoundingRect(sourceRange);
      range.load('address');
      targetRange.load('address');
      await context.sync();
      console.log("range" + range.address);
      console.log("targetRange" + targetRange.address);
      result = range.address == targetRange.address;
    });
    return result;
  }
  /**
   * prompt for user to select a range and return the range address and values
   * @returns [address, values]
   */
  async areaSelect(): Promise<[string, any[][]]> {
    try {
      const bindingId = await this.promptForRangeBindingId();
      const [address, values] = await this.getDataByBindingId(bindingId);
      return [address, values];
    } catch (error) {
      console.log(error);
      return await this.getSelectedRange();
    }
  }
  /**
   * return excel selected range address and values
   * @returns [address, values]
   */
  async getSelectedRange(): Promise<[string, any[][]]> {
    return new Promise((resolve, reject) => {
      Excel.run(async (ctx) => {
        const range = ctx.workbook.getSelectedRange();
        range.load("address,values");
        await ctx.sync();
        return resolve([range.address, range.values]);
      })
        .catch((error) => reject(error))
        .finally(() => {
          return Office.context.document.bindings.releaseByIdAsync("bindingId");
        });
    });
  }
  /**
   * set excel range value with array data
   * @param address excel range address
   * @param values array data
   * @returns void
  */
  async setRangeValue(address: string, values: any[][]): Promise<void> {
    await Excel.run(async (ctx) => {
      let range = ctx.workbook.worksheets.getActiveWorksheet().getRange(address);
      range.load("values");
      await ctx.sync();
      if (range.values.length < values.length) {
        range = range.getResizedRange(values.length - 1, values[0].length - 1);
      }
      range.values = values;
      await ctx.sync();
    });
  }
  /** write array data to excel visible range */
  async writeArrayToVisibleRange(startCellAddress: string,values: any[][]): Promise<void> {
    try {
      // write data to visible cells
      await Excel.run(async (context) => {
        const [worksheet,startCell] = startCellAddress.split("!"); 
        // get sheet by sheet name
        const sheet = context.workbook.worksheets.getItem(worksheet);
        // get range by cell address
        const target = sheet.getRange(startCell);
        const startRange = target.getCell(0, 0);
        let currentRange = startRange;
        startRange.load("rowIndex,columnIndex");
        await context.sync();
        let startRow = -1;
        // loop rows to filter out hidden row
        for (let i = 0; i < values.length; i++) {
          do {
            startRow = startRow + 1;
            currentRange = startRange.getOffsetRange(startRow, 0);
            currentRange.load("hidden");
            await context.sync();
          } while (currentRange.hidden);
          const columns = values[i];
          let startColumn = -1;
          // loop columns to filter out hidden column
          for (let c = 0; c < columns.length; c++) {
            do {
              startColumn = startColumn + 1;
              currentRange = startRange.getOffsetRange(startRow, startColumn);
              currentRange.load("hidden");
              await context.sync();
            } while (currentRange.hidden);
            currentRange.values = [[values[i][c]]];
          }
        }
        await context.sync();
      });
    } catch (error) {
      console.log(error);
    }
  }
  /** get excel used range column name and first row value
   * @param sheetName excel sheet name optional
   * @returns [columnNames, firstRowValues]
   */
  async getUsedRangeColumnIndexsNamesAndFirstRowValues(sheetName?: string): Promise<[string[], string[], any[]]> {
    return new Promise((resolve, reject) => {
      Excel.run(async (ctx) => {
        let sheet: Excel.Worksheet;
        if (!sheetName) {
          sheet = ctx.workbook.worksheets.getActiveWorksheet();
        } else {
          sheet = ctx.workbook.worksheets.getItem(sheetName);
        }
        const range = sheet.getUsedRange();
        range.load("address");
        await ctx.sync();
        const address = range.address;
        const [columnIndexs, columnNames, firstRowValues] = await this.getRangeColumnIndexsNamesAndFirstRowValues(address);
        return resolve([columnIndexs, columnNames, firstRowValues]);
      }).catch((error) => reject(error));
    });
  }
  /**
   * get excel range by address
   * return range column index, column names, first row values
   */
  async getRangeColumnIndexsNamesAndFirstRowValues(address: string): Promise<[string[], string[], any[]]> {
    const [sheet, rangeAddress] = address.replace(/'/g, "").split("!");
    return await Excel.run(async (ctx) => {
      const range = ctx.workbook.worksheets.getItem(sheet).getRange(rangeAddress);
      range.load("columnIndex,columnCount,values");
      await ctx.sync();
      // range start column index
      const startColumnIndex = range.columnIndex;
      const firstRowValues = range.values[0];
      const columnIndexs = [];
      const columnNames = [];
      for (let i = 0; i < firstRowValues.length; i++) {
        columnIndexs.push(startColumnIndex + i);
        columnNames.push(this.columnIndexToLetter(startColumnIndex + i));  
      }
      return [columnIndexs, columnNames, firstRowValues];
    });
  }
  //#region Sheet
  /**
   * get excel sheet by name, need to cal sheetResult.context.sync() before get sheet result properties
   * @param sheetName sheet name
   * @returns sheet object
   * @example const sheet = await getSheet("sheet1"); sheet.load("name"); sheet.context.sync(); console.log(sheet.name);
   */
  async getSheet(sheetName: string): Promise<Excel.Worksheet> {
    sheetName = sheetName.replace(/'/g, "");
    return await Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getItem(sheetName);
      await ctx.sync();
      return sheet;
    });
  }
  /**
   * get all excel sheet name for current workbook
   * @returns sheet name array
   */
  async getSheetNames(): Promise<string[]>{
    return await Excel.run(async (ctx) => {
      const worksheets = ctx.workbook.worksheets;
      worksheets.load('items/name');
      await ctx.sync();
      var sheetNames = worksheets.items.map(function (sheet) {
        return sheet.name;
      });
      return sheetNames;
    });
  }
  //#endregion
  //#region Range
  //#endregion
  //#region Column
  /** convert column index to letter */
  columnIndexToLetter(columnIndex: number): string {
    let temp, letter = "";
    let columnNumber = columnIndex + 1;
    while (columnNumber > 0) {
      temp = (columnNumber - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      columnNumber = (columnNumber - temp - 1) / 26;
    }
    return letter;
  }
  /**
   * excel convert column letter to index
   */
  columnLetterToIndex(columnLetter: string): number {
    let index = 0;
    for (let i = 0; i < columnLetter.length; i++) {
      index = index * 26 + columnLetter.charCodeAt(i) - 64;
    }
    return index - 1;
  }
  /** 
   * move excel column to specified position
   * @param column excel column index array
   * @param position excel column target index
   */
  async moveColumns(columnNames: string[], targetColumnName: string, sheetName?: string): Promise<void> {
    await Excel.run(async (context) => {
      let sheet;
      if (sheetName) {
        sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
      } else {
        sheet = context.workbook.worksheets.getActiveWorksheet();
      }
      const targetColumnIndex = this.columnLetterToIndex(targetColumnName);
      columnNames.forEach(async(columnName, index) => {
        await this.moveColumn(this.columnLetterToIndex(columnName), targetColumnIndex + index);
      });
      await context.sync();
    });
  }
  /**
   * move column from start to end by index
   * @param start column move index
   * @param end column target index
   */
  async moveColumn(start: number, end: number): Promise < void> {
    await Excel.run(async (context) => {
      const sheet: Excel.Worksheet = context.workbook.worksheets.getActiveWorksheet();
      if (start > end) {
        let startColumn: number = start + 1;
        let endColumn: number = end;
        sheet.getCell(0, endColumn).getEntireColumn().insert(Excel.InsertShiftDirection.right);
        sheet.getCell(0, startColumn).getEntireColumn().moveTo(sheet.getCell(0, endColumn).getEntireColumn());
        sheet.getCell(0, startColumn).getEntireColumn().delete(Excel.DeleteShiftDirection.left);
      } else {
        let startColumn: number = start;
        let endColumn: number = end + 1;
        sheet.getCell(0, endColumn).getEntireColumn().insert(Excel.InsertShiftDirection.right);
        sheet.getCell(0, startColumn).getEntireColumn().moveTo(sheet.getCell(0, endColumn).getEntireColumn());
        sheet.getCell(0, startColumn).getEntireColumn().delete(Excel.DeleteShiftDirection.left);
      }
      await context.sync();
    });    
  }
  /**
   * switch two column for excel
   * @param source column index
   * @param target column index
   * @param sheetName excel sheet name optional
   * @returns void
   */
  async switchColumn(source: string, target: string, sheetName?: string): Promise<void> {
    await Excel.run(async (context) => {
      let sheet;
      if (sheetName) {
        sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
      } else {
        sheet = context.workbook.worksheets.getActiveWorksheet();
      }
      let sourceIndex = this.columnLetterToIndex(source);
      let targetIndex = this.columnLetterToIndex(target);
      if (sourceIndex > targetIndex) {
        [sourceIndex, targetIndex] = [targetIndex, sourceIndex];
      }
      const middleColumnIndex = targetIndex + 1;
      sheet.getCell(0, targetIndex).getEntireColumn().insert(Excel.InsertShiftDirection.right);
      sheet.getCell(0, targetIndex).getEntireColumn().copyFrom(sheet.getCell(0, sourceIndex).getEntireColumn());
      sheet.getCell(0, sourceIndex).getEntireColumn().copyFrom(sheet.getCell(0, middleColumnIndex).getEntireColumn(), Excel.RangeCopyType.all, false, false);
      sheet.getCell(0, middleColumnIndex).getEntireColumn().delete(Excel.DeleteShiftDirection.left);
      // const middleColumn = this.columnIndexToLetter(this.columnLetterToIndex(target) + 1);
      // sheet.getRange(`${target}:${target}`).insert(Excel.InsertShiftDirection.right);
      // sheet.getRange(`${target}:${target}`).copyFrom(sheet.getRange(`${source}:${source}`));
      // if (this.compareColumnName(source, target)) {
      //   sheet.getRange(`${source}:${source}`).copyFrom(sheet.getRange(`${middleColumn}:${middleColumn}`), Excel.RangeCopyType.all, false, false);
      // } else {
      //   sheet.getRange(`${middleColumn}:${middleColumn}`).copyFrom(sheet.getRange(`${middleColumn}:${middleColumn}`), Excel.RangeCopyType.all, false, false);
      // }
      
      // sheet.getRange(`${middleColumn}:${middleColumn}`).delete(Excel.DeleteShiftDirection.left);
      await context.sync();
    });
  }
  /**
   * compare column name bigger or smaller
   * if source column name smaller than target column name return true
   * @param source column name
   * @param target column name
   * @returns boolean
   * @example source = "A", target = "B" return false
   * @example source = "DI", target = "DR" return false
   */
  compareColumnName(source: string, target: string): boolean {
    const sourceIndex = this.columnLetterToIndex(source);
    const targetIndex = this.columnLetterToIndex(target);
    return sourceIndex < targetIndex;
  }
  //#endregion
  //#region Cells
  /**
   * format selection cells with point align
   */
  async selectionPointAlign(): Promise<void>{
    // get selected value
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.load("values");
      await context.sync();
      const values = range.values;
      const lengths = utilsHelper.getMaxIntegerAndFractionLengths(values);
      const format = utilsHelper.generateMask(lengths);
      range.numberFormatLocal = [[format]];
      range.format.autofitColumns();
    });
  }
    /**
   * format selection cells with distribute align
   */
  async selectionDistributedAlign(indent: number = 0): Promise<void>{
    // get selected value
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.format.horizontalAlignment = Excel.HorizontalAlignment.distributed;  
      range.format.indentLevel = indent;     
      await context.sync();
    });
  }
  async replaceMeekouSplit(): Promise<void>{
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.load("values");  
      await context.sync();

      let values = range.values[0][0];
      values = values.replace(new RegExp("MeekouRowSplit", "g"), "<br style='mso-data-placement:same-cell;' />");
      range.values = [[values]];
      await context.sync();
    });
  }
  async swapArea(sourceArea: string, targetArea: string): Promise<void>{
    if (!sourceArea || !targetArea) {
      console.error("Both ranges must be selected before exchanging.");
      return;
    }
    await Excel.run(async (context) => {
      const source = sourceArea.replace(/'/g, '');
      const target = targetArea.replace(/'/g, '');
      const [sourceWorksheetName, sourceRangeAddress] = source.split(/!(.*)/);
      const [targetWorksheetName, targetRangeAddress] = target.split(/!(.*)/);

      const sourceRange = context.workbook.worksheets.getItem(sourceWorksheetName).getRange(sourceRangeAddress);
      const targetRange = context.workbook.worksheets.getItem(targetWorksheetName).getRange(targetRangeAddress);
      context.workbook.application.suspendScreenUpdatingUntilNextSync();
      // create middle worksheet
      const middleSheet = context.workbook.worksheets.add("Meekou");
      let middleRange = middleSheet.getUsedRange();
      middleRange.copyFrom(sourceRange, Excel.RangeCopyType.all);
      middleRange = middleSheet.getUsedRange();
      sourceRange.copyFrom(targetRange, Excel.RangeCopyType.all);
      targetRange.copyFrom(middleRange, Excel.RangeCopyType.all);      
      middleSheet.delete();
      await context.sync();
    }).catch(error => {
        console.error(error);
    });
  }
  async selectionFormulaToText():Promise<void> {
    await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.load("values");
      await context.sync();
      range.values = range.values;
    });
  }
  async workSheetsFormulaToText(sheetNames: string[]): Promise<void>{
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load('items, items/name');
      await context.sync();
      for (let index = 0; index < sheets.items.length; index++) {
        const sheet = sheets.items[index];
        if (sheetNames.includes(sheet.name)) {
          const range = sheet.getUsedRangeOrNullObject();
          range.load("values");
          await context.sync();
          range.values = range.values;
        }
      }
    });
  }
  async workbookFormulaToText():Promise<void> {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load('items');
      await context.sync();
      for (let index = 0; index < sheets.items.length; index++) {
        const sheet = sheets.items[index];
        const range = sheet.getUsedRangeOrNullObject();
        range.load("values");
        await context.sync();
        range.values = range.values;
      }
    });
  }
  //#endregion
}

export const excelHelper = new ExcelHelper();
