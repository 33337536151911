import moment from "moment";

//#region Base
export class MeekouResponse<T>{
    success:boolean;
    error:string;
    result:T
}

export class MeekouPagedResponse<T>{
    totalCount:number;
    items:Array<T>
}

export class PagedRequestDto{
    filter:string;
    sorting:string;
    skipCount:number = 0;
    maxResultCount:number = 10;
    public constructor(init:PagedRequestDto){
        Object.assign(this, init);
    }
}
//#endregion

//#region User
export enum LoginType {
    UserNamePassword = "UserNamePassword" as any,
    MiniProgram = "MiniProgram" as any,
    MiniProgramAuthorize = "MiniProgramAuthorize" as any,
    ScanMiniProgramQrCode = "ScanMiniProgramQrCode" as any,
    MicrosoftSSO = "MicrosoftSSO" as any
}
export class UserOwnProductDto implements IUserOwnProductDto {
    id: number;
    productId: number;
    shortName: string | undefined;
    expireDate: moment.Moment | undefined;
    availableTimes: number | undefined;
    isTrial: boolean;
    billingMethod: string | undefined;

    constructor(data?: IUserOwnProductDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.productId = _data["productId"];
            this.shortName = _data["shortName"];
            this.expireDate = _data["expireDate"] ? moment(_data["expireDate"].toString()) : <any>undefined;
            this.availableTimes = _data["availableTimes"];
            this.isTrial = _data["isTrial"];
            this.billingMethod = _data["billingMethod"];
        }
    }

    static fromJS(data: any): UserOwnProductDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserOwnProductDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["productId"] = this.productId;
        data["shortName"] = this.shortName;
        data["expireDate"] = this.expireDate ? this.expireDate.toISOString() : <any>undefined;
        data["availableTimes"] = this.availableTimes;
        data["isTrial"] = this.isTrial;
        data["billingMethod"] = this.billingMethod;
        return data; 
    }

    clone(): UserOwnProductDto {
        const json = this.toJSON();
        let result = new UserOwnProductDto();
        result.init(json);
        return result;
    }
}

export interface IUserOwnProductDto {
    id: number;
    productId: number;
    shortName: string | undefined;
    expireDate: moment.Moment | undefined;
    availableTimes: number | undefined;
    isTrial: boolean;
    billingMethod: string | undefined;
}
export class UserInfoDto implements IUserInfoDto {
    id: number;
    avatarUrl: string | undefined;
    displayName: string | undefined;
    nickName: string | undefined;
    phoneNumber: string | undefined;
    restIntegrals: number;
    integrals: number;
    isSign: boolean;
    viewVideoCount: number;
    availableProducts: UserOwnProductDto[] | undefined;

    constructor(data?: IUserInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.avatarUrl = _data["avatarUrl"];
            this.nickName = _data["nickName"];
            this.phoneNumber = _data["phoneNumber"];
            this.restIntegrals = _data["restIntegrals"];
            this.integrals = _data["integrals"];
            this.isSign = _data["isSign"];
            this.viewVideoCount = _data["viewVideoCount"];
            if (Array.isArray(_data["availableProducts"])) {
                this.availableProducts = [] as any;
                for (let item of _data["availableProducts"])
                    this.availableProducts.push(UserOwnProductDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): UserInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["avatarUrl"] = this.avatarUrl;
        data["nickName"] = this.nickName;
        data["phoneNumber"] = this.phoneNumber;
        data["restIntegrals"] = this.restIntegrals;
        data["integrals"] = this.integrals;
        data["isSign"] = this.isSign;
        data["viewVideoCount"] = this.viewVideoCount;
        if (Array.isArray(this.availableProducts)) {
            data["availableProducts"] = [];
            for (let item of this.availableProducts)
                data["availableProducts"].push(item.toJSON());
        }
        return data; 
    }

    clone(): UserInfoDto {
        const json = this.toJSON();
        let result = new UserInfoDto();
        result.init(json);
        return result;
    }
}

export interface IUserInfoDto {
    id: number;
    avatarUrl: string | undefined;
    nickName: string | undefined;
    phoneNumber: string | undefined;
    restIntegrals: number;
    integrals: number;
    isSign: boolean;
    viewVideoCount: number;
    availableProducts: UserOwnProductDto[] | undefined;
}
export class LoginDto implements ILoginDto {
    loginType: LoginType;
    id: string | undefined;
    code: string | undefined;
    isLogin: boolean;
    miniQrCode: string | undefined;
    accessToken: string;
    userInfoDto: UserInfoDto;

    constructor(data?: ILoginDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.loginType = _data["loginType"];
            this.id = _data["id"];
            this.code = _data["code"];
            this.isLogin = _data["isLogin"];
            this.miniQrCode = _data["miniQrCode"];
            this.accessToken = _data["accessToken"];
            this.userInfoDto = _data["userInfoDto"] ? UserInfoDto.fromJS(_data["userInfoDto"]) : <any>undefined;
        }
    }

    static fromJS(data: any): LoginDto {
        data = typeof data === 'object' ? data : {};
        let result = new LoginDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["loginType"] = this.loginType;
        data["id"] = this.id;
        data["code"] = this.code;
        data["isLogin"] = this.isLogin;
        data["miniQrCode"] = this.miniQrCode;
        data["accessToken"] = this.accessToken;
        data["officeAccessToken"] = this.officeAccessToken;
        data["userInfoDto"] = this.userInfoDto ? this.userInfoDto.toJSON() : <any>undefined;
        return data; 
    }

    clone(): LoginDto {
        const json = this.toJSON();
        let result = new LoginDto();
        result.init(json);
        return result;
    }
}

export interface ILoginDto {
    loginType: LoginType;
    id: string | undefined;
    code: string | undefined;
    isLogin: boolean;
    miniQrCode: string | undefined;
    accessToken: string | undefined;
    userInfoDto: UserInfoDto;
}

export interface IAddIntegralsToUserInput {
    name: string | undefined;
    integralType: IntegralType;
    integral: number;
    ownerId: number;
}
export class AddIntegralsToUserInput implements IAddIntegralsToUserInput {
    name: string | undefined;
    integralType: IntegralType;
    integral: number;
    ownerId: number;

    constructor(data?: IAddIntegralsToUserInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.integralType = _data["integralType"];
            this.integral = _data["integral"];
            this.ownerId = _data["ownerId"];
        }
    }

    static fromJS(data: any): AddIntegralsToUserInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddIntegralsToUserInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["integralType"] = this.integralType;
        data["integral"] = this.integral;
        data["ownerId"] = this.ownerId;
        return data; 
    }

    clone(): AddIntegralsToUserInput {
        const json = this.toJSON();
        let result = new AddIntegralsToUserInput();
        result.init(json);
        return result;
    }
}

export class RenewUserProductInput implements IRenewUserProductInput {
    id: number;
    userId: number;
    payUserId: number;
    productId: number;
    activationType: ActivationType;
    qty: number;
    integralPrice: number;
    moneyPrice: number;

    constructor(data?: IRenewUserProductInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.payUserId = _data["payUserId"];
            this.productId = _data["productId"];
            this.activationType = _data["activationType"];
            this.qty = _data["qty"];
            this.integralPrice = _data["integralPrice"];
            this.moneyPrice = _data["moneyPrice"];
        }
    }

    static fromJS(data: any): RenewUserProductInput {
        data = typeof data === 'object' ? data : {};
        let result = new RenewUserProductInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["payUserId"] = this.payUserId;
        data["productId"] = this.productId;
        data["activationType"] = this.activationType;
        data["qty"] = this.qty;
        data["integralPrice"] = this.integralPrice;
        data["moneyPrice"] = this.moneyPrice;
        return data; 
    }

    clone(): RenewUserProductInput {
        const json = this.toJSON();
        let result = new RenewUserProductInput();
        result.init(json);
        return result;
    }
}

export interface IRenewUserProductInput {
    id: number;
    userId: number;
    payUserId: number;
    productId: number;
    activationType: ActivationType;
    qty: number;
    integralPrice: number;
    moneyPrice: number;
}

export enum ActivationType {
    Trial = "体验" as any,
    GiveAwayWithIntegral = "积分赠送" as any,
    GiveAwayWithMoney = "付费赠送" as any,
    BuyWithIntegral = "积分购买" as any,
    BuyWithMoney = "付费购买" as any,
}

export class UserProductDto implements IUserProductDto {
    id: number;
    userId: number;
    user: UserDto;
    productId: number;
    product: ProductDto;
    expireDate: moment.Moment | undefined;
    availableTimes: number | undefined;

    constructor(data?: IUserProductDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? UserDto.fromJS(_data["user"]) : <any>undefined;
            this.productId = _data["productId"];
            this.product = _data["product"] ? ProductDto.fromJS(_data["product"]) : <any>undefined;
            this.expireDate = _data["expireDate"] ? moment(_data["expireDate"].toString()) : <any>undefined;
            this.availableTimes = _data["availableTimes"];
        }
    }

    static fromJS(data: any): UserProductDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserProductDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["productId"] = this.productId;
        data["product"] = this.product ? this.product.toJSON() : <any>undefined;
        data["expireDate"] = this.expireDate ? this.expireDate.toISOString() : <any>undefined;
        data["availableTimes"] = this.availableTimes;
        return data; 
    }

    clone(): UserProductDto {
        const json = this.toJSON();
        let result = new UserProductDto();
        result.init(json);
        return result;
    }
}

export interface IUserProductDto {
    id: number;
    userId: number;
    user: UserDto;
    productId: number;
    product: ProductDto;
    expireDate: moment.Moment | undefined;
    availableTimes: number | undefined;
}

export class UserDto implements IUserDto {
    id: number;
    userName: string;
    name: string;
    surname: string;
    emailAddress: string;
    openId: string | undefined;
    sessionKey: string | undefined;
    unionId: string | undefined;
    avatarUrl: string | undefined;
    isActive: boolean;
    fullName: string | undefined;
    lastLoginTime: moment.Moment | undefined;
    creationTime: moment.Moment;
    roleNames: string[] | undefined;

    constructor(data?: IUserDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userName = _data["userName"];
            this.name = _data["name"];
            this.surname = _data["surname"];
            this.emailAddress = _data["emailAddress"];
            this.openId = _data["openId"];
            this.sessionKey = _data["sessionKey"];
            this.unionId = _data["unionId"];
            this.avatarUrl = _data["avatarUrl"];
            this.isActive = _data["isActive"];
            this.fullName = _data["fullName"];
            this.lastLoginTime = _data["lastLoginTime"] ? moment(_data["lastLoginTime"].toString()) : <any>undefined;
            this.creationTime = _data["creationTime"] ? moment(_data["creationTime"].toString()) : <any>undefined;
            if (Array.isArray(_data["roleNames"])) {
                this.roleNames = [] as any;
                for (let item of _data["roleNames"])
                    this.roleNames.push(item);
            }
        }
    }

    static fromJS(data: any): UserDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userName"] = this.userName;
        data["name"] = this.name;
        data["surname"] = this.surname;
        data["emailAddress"] = this.emailAddress;
        data["openId"] = this.openId;
        data["sessionKey"] = this.sessionKey;
        data["unionId"] = this.unionId;
        data["avatarUrl"] = this.avatarUrl;
        data["isActive"] = this.isActive;
        data["fullName"] = this.fullName;
        data["lastLoginTime"] = this.lastLoginTime ? this.lastLoginTime.toISOString() : <any>undefined;
        data["creationTime"] = this.creationTime ? this.creationTime.toISOString() : <any>undefined;
        if (Array.isArray(this.roleNames)) {
            data["roleNames"] = [];
            for (let item of this.roleNames)
                data["roleNames"].push(item);
        }
        return data; 
    }

    clone(): UserDto {
        const json = this.toJSON();
        let result = new UserDto();
        result.init(json);
        return result;
    }
}

export interface IUserDto {
    id: number;
    userName: string;
    name: string;
    surname: string;
    emailAddress: string;
    openId: string | undefined;
    sessionKey: string | undefined;
    unionId: string | undefined;
    avatarUrl: string | undefined;
    isActive: boolean;
    fullName: string | undefined;
    lastLoginTime: moment.Moment | undefined;
    creationTime: moment.Moment;
    roleNames: string[] | undefined;
}

export enum IntegralType {
    SignIn = "签到" as any,
    ViewRewardedAds = "看激励广告" as any,
    BuyResources = "购买资源" as any,
    BuyProductWithIntegral = "积分购买产品" as any,
    Trial = "体验" as any,
    Customize = "自定义" as any
}

export class ChangeIntegralsInput implements IChangeIntegralsInput {
    name: string | undefined;
    integralType: IntegralType;
    integral: number;
    ownerId: number;

    constructor(data?: IChangeIntegralsInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.integralType = _data["integralType"];
            this.integral = _data["integral"];
            this.ownerId = _data["ownerId"];
        }
    }

    static fromJS(data: any): ChangeIntegralsInput {
        data = typeof data === 'object' ? data : {};
        let result = new ChangeIntegralsInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["integralType"] = this.integralType;
        data["integral"] = this.integral;
        data["ownerId"] = this.ownerId;
        return data; 
    }

    clone(): ChangeIntegralsInput {
        const json = this.toJSON();
        let result = new ChangeIntegralsInput();
        result.init(json);
        return result;
    }
}

export interface IChangeIntegralsInput {
    name: string | undefined;
    integralType: IntegralType;
    integral: number;
    ownerId: number;
}
//#endregion

//#region Product
export class ProductDto implements IProductDto {
    id: number;
    shortName: string | undefined;
    name: string | undefined;
    description: string | undefined;
    images: LinkDto[] | undefined;
    integralPrice: number;
    moneyPrice: number;
    billingMethod: string | undefined;
    references: LinkDto[] | undefined;

    constructor(data?: IProductDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.shortName = _data["shortName"];
            this.name = _data["name"];
            this.description = _data["description"];
            if (Array.isArray(_data["images"])) {
                this.images = [] as any;
                for (let item of _data["images"])
                    this.images.push(LinkDto.fromJS(item));
            }
            this.integralPrice = _data["integralPrice"];
            this.moneyPrice = _data["moneyPrice"];
            this.billingMethod = _data["billingMethod"];
            if (Array.isArray(_data["references"])) {
                this.references = [] as any;
                for (let item of _data["references"])
                    this.references.push(LinkDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ProductDto {
        data = typeof data === 'object' ? data : {};
        let result = new ProductDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["shortName"] = this.shortName;
        data["name"] = this.name;
        data["description"] = this.description;
        if (Array.isArray(this.images)) {
            data["images"] = [];
            for (let item of this.images)
                data["images"].push(item.toJSON());
        }
        data["integralPrice"] = this.integralPrice;
        data["moneyPrice"] = this.moneyPrice;
        data["billingMethod"] = this.billingMethod;
        if (Array.isArray(this.references)) {
            data["references"] = [];
            for (let item of this.references)
                data["references"].push(item.toJSON());
        }
        return data; 
    }

    clone(): ProductDto {
        const json = this.toJSON();
        let result = new ProductDto();
        result.init(json);
        return result;
    }
}

export interface IProductDto {
    id: number;
    shortName: string | undefined;
    name: string | undefined;
    description: string | undefined;
    images: LinkDto[] | undefined;
    integralPrice: number;
    moneyPrice: number;
    billingMethod: string | undefined;
    references: LinkDto[] | undefined;
}
export class ProductPagedRequestDto extends PagedRequestDto{
    public constructor(init:ProductPagedRequestDto){
        super(init);
        Object.assign(this, init);
    }
}
export class ProductPagedResponse extends MeekouResponse<MeekouPagedResponse<ProductDto>>{
}
//#endregion

//#region Link
export class LinkDto implements ILinkDto {
    id: number;
    title: string | undefined;
    url: string | undefined;
    order: number;
    linkType: LinkType;
    productImageId: number | undefined;
    productReferenceId: number | undefined;
    isDeleted: boolean | undefined;

    constructor(data?: ILinkDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.title = _data["title"];
            this.url = _data["url"];
            this.order = _data["order"];
            this.linkType = _data["linkType"];
            this.productImageId = _data["productImageId"];
            this.productReferenceId = _data["productReferenceId"];
            this.isDeleted = _data["isDeleted"];
        }
    }

    static fromJS(data: any): LinkDto {
        data = typeof data === 'object' ? data : {};
        let result = new LinkDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["title"] = this.title;
        data["url"] = this.url;
        data["order"] = this.order;
        data["linkType"] = this.linkType;
        data["productImageId"] = this.productImageId;
        data["productReferenceId"] = this.productReferenceId;
        data["isDeleted"] = this.isDeleted;
        return data; 
    }

    clone(): LinkDto {
        const json = this.toJSON();
        let result = new LinkDto();
        result.init(json);
        return result;
    }
}

export interface ILinkDto {
    id: number;
    title: string | undefined;
    url: string | undefined;
    order: number;
    linkType: LinkType;
    productImageId: number | undefined;
    productReferenceId: number | undefined;
    isDeleted: boolean | undefined;
}

export enum LinkType {
    Image = "Image" as any,
    Video = "Video" as any,
    Article = "Article" as any,
    Excel = "Excel" as any,
    Word = "Word" as any,
    File = "File" as any,
    Zip = "Zip" as any,
}
//#endregion

//#region Blogs
export class BlogPagedResponse extends MeekouResponse<MeekouPagedResponse<BlogDto>>{
}

export class BlogPagedRequestDto extends PagedRequestDto{
    public constructor(init:BlogPagedRequestDto){
        super(init);
        Object.assign(this, init);
    }
}

export class BlogDto implements IBlogDto {
    id: number;
    title: string | undefined;
    subTitle: string | undefined;
    imgUrl: string | undefined;
    description: string | undefined;
    content: string | undefined;
    weChatMpUrl: string | undefined;
    weChatMpVideoUrl: string | undefined;
    heCaiYunVideoMiniProgUrl: string | undefined;
    heCaiYunFileMiniProgUrl: string | undefined;
    integral: number;
    isShown: boolean;
    isSwiper: boolean;
    isMiniProgramEnabled: boolean;
    isWeChatEnabled: boolean;
    isWebEnabled: boolean;
    orderIndex: number;

    constructor(data?: IBlogDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.title = _data["title"];
            this.subTitle = _data["subTitle"];
            this.imgUrl = _data["imgUrl"];
            this.description = _data["description"];
            this.content = _data["content"];
            this.weChatMpUrl = _data["weChatMpUrl"];
            this.weChatMpVideoUrl = _data["weChatMpVideoUrl"];
            this.heCaiYunVideoMiniProgUrl = _data["heCaiYunVideoMiniProgUrl"];
            this.heCaiYunFileMiniProgUrl = _data["heCaiYunFileMiniProgUrl"];
            this.integral = _data["integral"];
            this.isShown = _data["isShown"];
            this.isSwiper = _data["isSwiper"];
            this.isMiniProgramEnabled = _data["isMiniProgramEnabled"];
            this.isWeChatEnabled = _data["isWeChatEnabled"];
            this.isWebEnabled = _data["isWebEnabled"];
            this.orderIndex = _data["orderIndex"];
        }
    }

    static fromJS(data: any): BlogDto {
        data = typeof data === 'object' ? data : {};
        let result = new BlogDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["title"] = this.title;
        data["subTitle"] = this.subTitle;
        data["imgUrl"] = this.imgUrl;
        data["description"] = this.description;
        data["content"] = this.content;
        data["weChatMpUrl"] = this.weChatMpUrl;
        data["weChatMpVideoUrl"] = this.weChatMpVideoUrl;
        data["heCaiYunVideoMiniProgUrl"] = this.heCaiYunVideoMiniProgUrl;
        data["heCaiYunFileMiniProgUrl"] = this.heCaiYunFileMiniProgUrl;
        data["integral"] = this.integral;
        data["isShown"] = this.isShown;
        data["isSwiper"] = this.isSwiper;
        data["isMiniProgramEnabled"] = this.isMiniProgramEnabled;
        data["isWeChatEnabled"] = this.isWeChatEnabled;
        data["isWebEnabled"] = this.isWebEnabled;
        data["orderIndex"] = this.orderIndex;
        return data; 
    }

    clone(): BlogDto {
        const json = this.toJSON();
        let result = new BlogDto();
        result.init(json);
        return result;
    }
}

export interface IBlogDto {
    id: number;
    title: string | undefined;
    subTitle: string | undefined;
    imgUrl: string | undefined;
    description: string | undefined;
    content: string | undefined;
    weChatMpUrl: string | undefined;
    weChatMpVideoUrl: string | undefined;
    heCaiYunVideoMiniProgUrl: string | undefined;
    heCaiYunFileMiniProgUrl: string | undefined;
    integral: number;
    isShown: boolean;
    isSwiper: boolean;
    isMiniProgramEnabled: boolean;
    isWeChatEnabled: boolean;
    isWebEnabled: boolean;
    orderIndex: number;
}

export class BlogDtoPagedResultDto implements IBlogDtoPagedResultDto {
    items: BlogDto[] | undefined;
    totalCount: number;

    constructor(data?: IBlogDtoPagedResultDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["items"])) {
                this.items = [] as any;
                for (let item of _data["items"])
                    this.items.push(BlogDto.fromJS(item));
            }
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): BlogDtoPagedResultDto {
        data = typeof data === 'object' ? data : {};
        let result = new BlogDtoPagedResultDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.items)) {
            data["items"] = [];
            for (let item of this.items)
                data["items"].push(item.toJSON());
        }
        data["totalCount"] = this.totalCount;
        return data; 
    }

    clone(): BlogDtoPagedResultDto {
        const json = this.toJSON();
        let result = new BlogDtoPagedResultDto();
        result.init(json);
        return result;
    }
}

export interface IBlogDtoPagedResultDto {
    items: BlogDto[] | undefined;
    totalCount: number;
}
//#endregion