import React from "react";
import { Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { AppProps } from "../App";
import { messageObserver } from "../redux/observers";
import { store } from "../redux/store";
import Settings from "../settings/Settings";
import ImageResize from "./tools/ImageResize";

const CommonApp = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);

    return (
        <Routes>  
            
            <Route path="settings/settings" element={<Settings />} />
            <Route path="tools/imageresize" element={<ImageResize />} />                      
        </Routes>
    );
  };

export default CommonApp;