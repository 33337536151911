import { DefaultButton, IStackItemStyles, IStackTokens, Stack, StackItem } from "@fluentui/react";
import { Button, makeStyles } from "@fluentui/react-components";
import { t } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import { excelHelper } from "../../services/excel.helper";

const useStyles = makeStyles({
    confirmButton: {
      width:'100%'
    },
});

const AreaSwap = () =>{
    const [sourceArea, setSourceArea] = React.useState("");
    const [targetArea, setTargetArea] = React.useState("");
    const sourceAreaOnChange = React.useCallback((address, values) => {
        setSourceArea(address);
      }, []);
    const targetAreaOnChange = React.useCallback((address, values) => {
        setTargetArea(address);
    }, []);
    const onAreaSwap = async () =>{
        await excelHelper.swapArea(sourceArea, targetArea);
    }
    const stackTokens: IStackTokens = { childrenGap: 10, padding: 10 };
    const styles = useStyles();
    return(
        <div>
            <Stack tokens={stackTokens}>
                <StackItem>
                    <AreaSelect
                        title={t("selectSourceArea")}
                        selectedAddress={sourceArea}
                        onChange={sourceAreaOnChange}
                    />
                </StackItem>
                <StackItem>
                    <AreaSelect
                        title={t("selectTargetArea")}
                        selectedAddress={targetArea}
                        onChange={targetAreaOnChange}
                    />
                </StackItem>
                <StackItem>
                    <DefaultButton onClick={onAreaSwap} className={styles.confirmButton}>{t("confirm")}</DefaultButton>
                </StackItem>
            </Stack>
        </div>
    )
}

export default withTranslation()(AreaSwap);