import React from "react";
import { withTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { t } from "i18next";
import { FileType } from "../../models/fileType";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { Button } from "@fluentui/react-button";
import { ArrowExportLtr24Filled } from "@fluentui/react-icons";
import * as FileSaver from "file-saver";
import { Input, InputOnChangeData, Label } from "@fluentui/react-components";
import { utilsHelper } from "../../services/utilsHelper";

const Export = () => {
  const [exportArea, setExportArea] = React.useState("");
  const [exportData, setExportData] = React.useState([]);
  const [exportType, setExportType] = React.useState(FileType.JSON);
  const [exportFileName, setExportFileName] = React.useState(t("meekouShare"));
  const exportOptions = [
    { key: FileType.JSON, text: t(FileType.JSON) },
    //{ key: FileType.XML, text: t(FileType.XML) },
  ];
  const areaSelectOnChange = React.useCallback((address, values) => {
    setExportArea(address);
    setExportData(values);
  }, []);
  const exportTypeChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>) => {
    setExportType(option.key as FileType);
  };
  const exportFileNameChange = (_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setExportFileName(data.value);
  };

  const exportToFile = () => {
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    const dataJson = XLSX.utils.sheet_to_json(worksheet);
    const dataJsonString = JSON.stringify(dataJson);
    var blob = new Blob([""], { type: "text/*;charset=utf-8" });
    switch (exportType) {
      case FileType.JSON:
        blob = new Blob([dataJsonString], { type: "application/json;charset=utf-8" });
        break;
      // case FileType.XML:
      //   var xmlData = utilsHelper.json2xml(dataJsonString);
      //   console.log(xmlData);
      //   blob = new Blob([xmlData], { type: "application/xml;charset=utf-8" });
      //   break;
      default:
        break;
    }
    FileSaver.saveAs(blob, `${exportFileName}.${exportType.toLowerCase()}`);
  };
  return (
    <div>
      <AreaSelect
        title={t("selectArea")}
        selectedAddress={exportArea}
        selectedValues={exportData}
        onChange={areaSelectOnChange}
      />
      <Dropdown
        label={t("exportType")}
        defaultSelectedKey={exportType}
        options={exportOptions}
        onChange={exportTypeChange}
      />
      <Label>{t("fillFileName")}</Label>
      <Input value={exportFileName} onChange={exportFileNameChange} />
      <Button onClick={exportToFile} icon={<ArrowExportLtr24Filled />} iconPosition="after">
        {t("export")}
      </Button>
    </div>
  );
};

export default withTranslation()(Export);
