import { Image, Label, makeStyles } from "@fluentui/react-components";
import { t } from "i18next"
import React from "react"
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
    root: {
      "> label": {
        textAlign: 'center',        
      },
    },
  });
const MeekouComingSoon = () =>{
    const styles = useStyles();
    return(
        <div className={styles.root}>
            <Label>
                {t('comingSoon')}
            </Label>
            <Image src="./assets/info/微信公众号_8CM.jpg" />
        </div>
    )
}

export default withTranslation()(MeekouComingSoon);