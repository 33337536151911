import React from "react";
import { withTranslation } from "react-i18next";

const FindReplace = () =>{
    return(
        <>
            This is FindReplace;
        </>
    )
}

export default withTranslation()(FindReplace);