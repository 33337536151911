export class MeekouConst{
    static meekouImage:string = "http://qiniu.meekou.cn/%E7%B1%B3%E5%8F%AF%E7%88%B1%E5%88%86%E4%BA%AB/%E7%B1%B3%E5%8F%AF%E7%88%B1%E5%88%86%E4%BA%AB.png";
    static whiteUserList: string[] = ['米可','Edward'];
    static userInfoKey = "userInfoKey";
    static accessTokenKey = "accessTokenKey";
    static signInKey = "登陆";
    static signIntegral = 1;
    static viewAdsKey = "看视频广告";
    static viewAdsIntegral = 2;
    static buyResourcesKey = "购买资源";
}