import fetch from "node-fetch";

export async function http<T>(request: RequestInfo): Promise<IHttpResponse<T>> {
  const response: IHttpResponse<T> = await fetch(request);
  response.parsedBody = await response.json();
  return response;
}
export interface IHttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function get<T>(
  path: string,
  accessToken: string = "",
  args: RequestInit = {
    method: "get",
    headers: {
      authorization: accessToken,
    },
  }
): Promise<IHttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  accessToken: string = "",
  args: RequestInit = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json;charset=utf-8",
      authorization: accessToken,
    },
    method: "post",
    body: JSON.stringify(body),
  }
): Promise<IHttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "put", body: JSON.stringify(body) }
): Promise<IHttpResponse<T>> {
  return await http<T>(new Request(path, args));
}
