/* global OfficeRuntime */
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "../redux/hooks";
import { AppConsts } from "../shared/appconsts";
import { mangoClient } from "./mangoClient";

export class AuthService {
  public dispatch = useAppDispatch();
  public instance  = useMsal();
  

  async login(): Promise<void> {
    let accessToken: string = await OfficeRuntime.storage.getItem(AppConsts.AccessToken);
    if (accessToken == null || accessToken.length === 0) {
      // show dialog to login
    }
    // toDo check whether accessToken is valid
  }

  async microsoftDialogLogin(): Promise<void>{
    // show auth dialog
    var fullUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + "/fallbackauthdialog.html";
    Office.context.ui.displayDialogAsync(fullUrl, { height: 60, width: 30 }, authDialogCallback);
  }
}
//#region Microsoft Auth
var authDialog: Office.Dialog;
async function authDialogCallback(arg: Office.AsyncResult<Office.Dialog>) {
  console.log("Auth Dialog has initialized. Wiring up events");
  authDialog = arg.value;
  authDialog.addEventHandler(Office.EventType.DialogMessageReceived, messageFromDialog);
  setTimeout(function (): void {
    authDialog.messageChild(JSON.stringify({
      message: "start login"
    }));
  }, 1000);

}
async function messageFromDialog(arg): Promise<void> {
  console.log(JSON.stringify(arg.message)); 
  const authResult = JSON.parse(arg.message);
  authDialog.close();
}
//#endregion

