import { Button, Dropdown,Option, Label, Select, SelectOnChangeData, SelectTabData, SelectTabEvent, Tab, TabList, TabValue, makeStyles, shorthands, Overflow, OverflowItem, useOverflowMenu, tokens, Menu, MenuTrigger, MenuPopover, MenuList, useIsOverflowItemVisible } from "@fluentui/react-components";
import { t } from "i18next";
import React, { ChangeEvent, useState } from "react";
import { withTranslation } from "react-i18next";
import { Sheet } from "../../models/excel.models";
import { excelHelper } from "../../services/excel.helper";
import { utilsHelper } from "../../services/utilsHelper";
import AreaSwap from "./AreaSwap";
import CellAlign from "./CellAlign";
import CellMerge from "./CellMerge";

const useStyles = makeStyles({
    root: {
        
    },
    dropdown:{
        width: '100%'
    }
  });
const CellOperation = () =>{
    const CellTabs= [
        {Id: "CellFormulaToText", Value: "CellFormulaToText"},
        {Id: "AreaSwap", Value: "AreaSwap"},        
        {Id: "CellAlign", Value: "CellAlign"},  
        {Id: "MergeCells", Value: "MergeCells"},      
    ];    
    const [activeTab, setActiveTab] = useState<TabValue>(CellTabs[0].Value);
    const onCellTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setActiveTab(data.value);
      };
    const CellFormulaToText = () =>{
        const Scopes = ["Workbook","Worksheet","Selection"];
        const [selectdScope, setSelectedScope] = useState(Scopes[0]);
        const onScopeSelect = (ev: ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData)=>{
            setSelectedScope(data.value)
        }
        const ConvertSelectionFormulaToText = () =>{
            const SelectionFormulaToText = async () =>{
                await excelHelper.selectionFormulaToText();
            }
            return(
                <Button onClick={SelectionFormulaToText}>{t('confirm')}</Button>
            )
        }
        const ConvertWorksheetFormulaToText = () =>{
            const [sheets, setSheets] = React.useState([]);
            const [selectedSheets, setSelectedSheets] = React.useState([]);
            React.useEffect(() => {
                const initSheets = async () => {
                  let items: Sheet[] = [];
                  items.push({
                    name: "All",
                  });
                  await Excel.run(async (context) => {
                    let sheets: Excel.WorksheetCollection = context.workbook.worksheets;
                    sheets.load("items/name, items/tabColor");
                    await context.sync();
                    sheets.items.forEach((s) => {
                      items.push({
                        name: s.name,
                        tabColor: s.tabColor,
                      });
                    });
                  });
                  setSheets(items);
                };
                initSheets().catch(console.error);
              }, []);
              const onSheetsSelectedChange = (event, data) => {
                let isAllClick = data.optionValue === "All";
                let isAllSelected = data.selectedOptions.includes("All");
                if (isAllClick) {
                  if (isAllSelected) {
                    setSelectedSheets(sheets.map((sheet) => sheet.name));
                  } else{
                    setSelectedSheets([]);
                  }
                } else {
                  setSelectedSheets(data.selectedOptions.filter((s) => s != "All"));
                }
              };
              const WorksheetsFormulaToText = async () =>{
                await excelHelper.workSheetsFormulaToText(selectedSheets);
              }
            const styles = useStyles();
            return(
                <div className={styles.root}>
                    <Dropdown multiselect placeholder={t("SelectWorkSheets")} 
                        className={styles.dropdown}
                        selectedOptions={selectedSheets} 
                        onOptionSelect={onSheetsSelectedChange}>
                        {sheets.map((sheet) => (
                            <Option key={sheet.name}>{sheet.name}</Option>
                        ))}
                    </Dropdown>
                    <Button onClick={WorksheetsFormulaToText}>{t('confirm')}</Button>
                </div>
            )
        }
        const ConvertWorkbookFormulaToText = () =>{
            const WorkbookFormulaToText = async () =>{
                await excelHelper.workbookFormulaToText();
            }
            return(
                <Button onClick={WorkbookFormulaToText}>{t('confirm')}</Button>
            )
        }
        return(
            <>
                <Label>{t('SelectScope')}</Label>
                <Select placeholder={t("SelectScope")} defaultValue={selectdScope} onChange={onScopeSelect}>
                    {Scopes.map((scope) => (
                        <option key={scope}>{t(scope)}</option>
                    ))}
                </Select>
                {selectdScope === "Selection" && <ConvertSelectionFormulaToText />}
                {selectdScope === "Worksheet" && <ConvertWorksheetFormulaToText />}
                {selectdScope === "Workbook" && <ConvertWorkbookFormulaToText />}
            </>
        )
    }
    return(
        <>
          <TabList selectedValue={activeTab} onTabSelect={onCellTabSelect}>
              {CellTabs.map(cellTab =>{
                  return(
                    <Tab id={cellTab.Id} key={cellTab.Id} value={cellTab.Value}>
                      {t(cellTab.Value)}
                  </Tab>
                  )
              })}
            </TabList>
            <>
                {activeTab === "CellFormulaToText" && <CellFormulaToText />}
                {activeTab === "MergeCells" && <CellMerge />}
                {activeTab === "CellAlign" && <CellAlign />}
                {activeTab === "AreaSwap" && <AreaSwap />}
                
            </>
        </>
    )
}

export default withTranslation()(CellOperation);