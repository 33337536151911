/* eslint-disable no-unused-vars */
import { Button, ButtonProps, Input, Label, makeStyles, shorthands } from "@fluentui/react-components";
import { SelectObjectSkewDismiss24Regular } from "@fluentui/react-icons";
import * as React from "react";
import { t } from "i18next";
import { excelHelper } from "../../services/excel.helper";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
});

const AreaSelectButton: React.FC<ButtonProps> = (props) => {
  return <Button {...props} appearance="transparent" icon={<SelectObjectSkewDismiss24Regular />} size="small" />;
};
interface AreaSelectProps {
  title?: string;
  selectedAddress?: string;
  selectedValues?: any[][];
  onChange?: (address, values) => void;
}
const AreaSelect = (props: AreaSelectProps) => {
  const styles = useStyles();
  const [selectedAddress, setSelectedAddress] = React.useState(props.selectedAddress);
  const [selectedValues, setSelectedValues] = React.useState(props.selectedValues);
  React.useEffect(() =>{
    const init =async () => {
      let [address, values] = await excelHelper.getSelectedRange();
      setSelectedAddress(address);
      setSelectedValues(values);
      props.onChange(address, values);
    }
    init().catch(e => console.log(e));
  }, [])
  const selectArea = async () => {
    let [address, values] = await excelHelper.areaSelect();
    setSelectedAddress(address);
    setSelectedValues(values);
    if (props.onChange) {
      props.onChange(address, values);
    }
  };
  return (
    <div className={styles.root}>
      <Label>{props.title ?? t("selectArea")}</Label>
      <Input value={selectedAddress} contentAfter={<AreaSelectButton onClick={selectArea} />} />
    </div>
  );
};

export default AreaSelect;
