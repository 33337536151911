import React from "react";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import MeekouLayout from "../../MeekouLayout";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import AccessControl from "../../controls/accessControlComponent/accessControl";
import { Button, Input, InputOnChangeData, Label, Spinner } from "@fluentui/react-components";
import { axiosClient, ExcelService, utilityService } from "meekou-ts";
import FileSaver from "file-saver";
import JsZip from 'jszip';
import { useAppDispatch } from "../../redux/hooks";
import { triggerLoading } from "../../redux/commonSlice";
import { LoadingProps } from "../../controls/loading/loading";

const LinkDownload = () =>{
    const dispatch = useAppDispatch();
    const [linkArea, setLinkAreaState] = React.useState("");
    const [fileNameArea, setFileNameAreaState] = React.useState("");
    const [interval, setInterval] = React.useState(1000);
    const linkAreaSelectOnChange = React.useCallback((area) => {
        setLinkAreaState(area);
      }, []);
    const fileNameAreaSelectOnChange = React.useCallback((area) => {
        setFileNameAreaState(area);
    }, []);
    const intervalOnChange = (_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>{
        if (data.value !== undefined) {
            const parsedValue = parseFloat(data.value);
            if (!isNaN(parsedValue)) {
                setInterval(parsedValue);
            } else {
                setInterval(undefined);
            }
          } else{
            setInterval(undefined);
        }
    }
    const DownloadLinks = async () =>{
        const links = [];
        const fileNames = [];
        dispatch(triggerLoading<LoadingProps>({
            shown: true,
            label: t('downloading')
        }))        
        // get cells from linkArea and set to links
        await Excel.run(async (context) => {
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            const linkRange = sheet.getRange(linkArea);
            linkRange.load("values");
            await context.sync();
            for (let rowIndex = 0; rowIndex < linkRange.values.length; rowIndex++) {
                const row = linkRange.values[rowIndex];
                for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                    const cell = row[columnIndex];
                    links.push(cell);
                }
            }
            console.log(links);
            const fileNameRange = sheet.getRange(fileNameArea);
            fileNameRange.load("values");
            await context.sync();
            for (let rowIndex = 0; rowIndex < fileNameRange.values.length; rowIndex++) {
                const row = fileNameRange.values[rowIndex];
                for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                    const cell = row[columnIndex];
                    fileNames.push(cell);
                }
            }
            console.log(fileNames);
            const zip = new JsZip();
            let logs = [];
            for (let index = 0; index < links.length; index++) {
                const link = links[index];
                const fileName = fileNames.length >= index && fileNames[index] != "" ? fileNames[index] : `Meekou${index}`;
                try {
                    const fileContent = await axiosClient.get<any>(link, { responseType: 'blob' });                    
                    zip.file(fileName, fileContent);
                } catch (error) {
                    logs.push({
                        Name: fileName,
                        Error: error
                    })
                }
                await utilityService.sleep(interval);
            }
            if (logs.length > 0) {
                zip.file("Logs.json", JSON.stringify(logs));
            }
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            FileSaver(zipBlob, 'Meekou.zip');
        });
        dispatch(triggerLoading<LoadingProps>({
          shown: false
      })) 
    }
    return (
        <MeekouLayout isReady>
          <AccessControl>
            <AreaSelect title={t('PickLinkArea')} selectedAddress={linkArea} onChange={linkAreaSelectOnChange} />
            <AreaSelect title={t('PickFileNameArea')} selectedAddress={fileNameArea} onChange={fileNameAreaSelectOnChange} />
            <Label>{t("FillRequetInterval")}</Label>
            <Input type="number" value={interval !== undefined ? interval.toString() : t('FillValidNumber')} onChange={intervalOnChange} />
            <Button onClick={DownloadLinks}>{t('download')}</Button>
          </AccessControl>          
        </MeekouLayout>
      );
}

export default withTranslation()(LinkDownload);