import { Button } from "@fluentui/react-components";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { excelHelper } from "../../services/excel.helper";
import { utilsHelper } from "../../services/utilsHelper";

const CellMerge = () =>{
    const [html, setHtml] = useState("");
    const Copy =async () => {
        const htmlContent = await utilsHelper.readHtmlFromClipboard();
        setHtml(htmlContent);
    }
    const combineHtmlTables = (html: string): string =>{
        const columnSplit = "MeekouColumnSplit";
        const rowSplit = "MeekouRowSplit";
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const table = doc.querySelector('table');
      
        if (!table) return '';
      
        let combinedContent = '';
      
        table.querySelectorAll('tr').forEach((row) => {
          row.querySelectorAll('td').forEach((cell) => {
            combinedContent += cell.innerHTML + 'MeekouColumnSplit';
          });
          combinedContent += "<br style='mso-data-placement:same-cell;' />";
        });
      
        // Create a new div element to hold the combined content
        const combinedContentDiv = document.createElement('div');
        combinedContentDiv.innerHTML = combinedContent.trim();
        console.log(doc);
        // Replace the original table with the combined content
        table.parentNode.replaceChild(combinedContentDiv, table);
        console.log(doc);
        // Return the outer HTML of the document to include the modified content
        return doc.documentElement.outerHTML;
    }
    const mergeCells = async () =>{
        // const htmlContent = await utilsHelper.readHtmlFromClipboard();
        // console.log(htmlContent);
        // for (let index = 0; index < htmlContent.length; index++) {
        //     let element = htmlContent[index][0] as string;
        //     element = element.replace("ab","www");
        // }
        // const richText = "<b style='color: red;'>This is bold red text</b> and <i style='color: blue;'>this is italic blue text</i>";
        const content = combineHtmlTables(html);
        console.log(content);
        await utilsHelper.writeHtmlToClipboard(html);
    }
    const Replace = async() =>{
        await excelHelper.replaceMeekouSplit();
    }
    return(
        <>
            <Button onClick={Copy}>Copy</Button> 
            <Button onClick={mergeCells}>MergeCells</Button> 
            <Button onClick={Replace}>Replace</Button>
        </>
    )
}

export default withTranslation()(CellMerge);