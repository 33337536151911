import { Button, Field, Label, makeStyles, Select, SelectOnChangeData, SpinButton, SpinButtonChangeEvent, SpinButtonOnChangeData, SpinButtonProps, useId } from "@fluentui/react-components";
import { t } from "i18next";
import React, { ChangeEvent, useState } from "react";
import { withTranslation } from "react-i18next";
import { excelHelper } from "../../services/excel.helper";
import { utilsHelper } from "../../services/utilsHelper";

const useStyles = makeStyles({
    root: {
        
    },
    confirm:{
        width: '100%'
    }
  });
  
const CellAlign = () =>{
    const styles= useStyles();
    const AlignTypes = [
        {key:"PointAlign", name: "PointAlign"},
        {key:"DistributedAlign", name: "DistributedAlign"}
    ]
    const [selectedAlignType, setSelectedAlignType] = useState(AlignTypes[0].key);
    const [indentLevel, setIndentLevel] = useState(0);
    const onAlignTypeSelect = (ev: ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData) =>{
        setSelectedAlignType(data.value);
    }
    const onAlignCell = async () =>{
        if (selectedAlignType == "PointAlign") {
            await excelHelper.selectionPointAlign();   
        } else if (selectedAlignType == "DistributedAlign") {
            await excelHelper.selectionDistributedAlign(indentLevel);
        }
        console.log(selectedAlignType);
    }
    const onIndentChange : SpinButtonProps["onChange"] = React.useCallback(
        (_ev, data) => {
          console.log("onSpinButtonChange", data.value, data.displayValue);
          if (data.value !== undefined) {
            setIndentLevel(data.value);
          } else if (data.displayValue !== undefined) {
            const newValue = parseFloat(data.displayValue);
            if (!Number.isNaN(newValue)) {
                setIndentLevel(newValue);
            } else {
              console.error(`Cannot parse "${data.displayValue}" as a number.`);
            }
          }
        },
        [setIndentLevel]
      );
    const id = useId();
    return(
        <>
            <Label>{t('SelectAlignType')}</Label>
            <Select placeholder={t("SelectAlignType")} defaultValue={selectedAlignType} onChange={onAlignTypeSelect}>
                {AlignTypes.map((alignType) => (
                    <option key={alignType.key} value={alignType.key}>{t(alignType.name)}</option>
                ))}
            </Select>
            {
                selectedAlignType == "DistributedAlign" &&
                <Field label={t("indent")}>
                    <SpinButton
                        value={indentLevel}
                        onChange={onIndentChange}
                        id={id}
                    />
                </Field>
            }
            <Button onClick={onAlignCell} className={styles.confirm}>{t("confirm")}</Button>
        </>
    )
}

export default withTranslation()(CellAlign);