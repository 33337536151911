import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { AppProps } from "../App";
import ImageRemoveBackGround from "./ImageRemoveBackGround";
import { messageObserver } from "../redux/observers";
import { store } from "../redux/store";

const ToolApp = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);

    return (
        <HashRouter basename="tool">
            <Routes>   
                <Route path="image">
                    <Route path="removebg" element={<ImageRemoveBackGround />} />  
                </Route>                        
            </Routes>
        </HashRouter>
    );
  };

export default ToolApp;