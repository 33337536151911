import { Button } from "@fluentui/react-components";
import moment from "moment";
import React from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import meekouApi, { UserInfoDto } from "../../services/meekouapi";
import { useAppSelector } from "../../redux/hooks";

interface AccessControlProps {
  requireAuth?: boolean;
  requiredProduct?: string;
  children?: any;
}

const AccessControl = (props: AccessControlProps) => {
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [userInfos, setUserInfos] = React.useState(Object.assign({}, new UserInfoDto()));
  const user = useAppSelector(state => state.user.userInfo);
  React.useEffect(() => {
    const getUserInfos = async () => {
      let userInfos = await meekouApi.getUserInfoById(user.id);
      console.log(userInfos);
      setUserInfos(userInfos);
      if (userInfos && userInfos.availableProducts) {
        let product = userInfos.availableProducts.find((p) => p.shortName == props.requiredProduct);
        if (product && moment(product.expireDate) > moment()) {
          setIsAuthorized(true);
        }
      }
    };
    if (props.requireAuth && props.requiredProduct){
      getUserInfos().catch(console.error);
    } else{
      setIsAuthorized(true);
    }
  }, []);
  return (
    <div>
      {
        props.requireAuth && !user.id && 
        <Link to="/settings/settings">
            <Button>{t("login")}</Button>
        </Link>
      }
      {
        props.requireAuth && user.id && !isAuthorized &&
        t("NotAuthorized")
      }
      {
        isAuthorized &&
        props.children
      }
    </div>
  );
};

export default AccessControl;
