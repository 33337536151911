class Utils {
  /**
   * delay for minutes
   * @param ms millisecond
   */
  public delay = (ms: number): Promise<{}> => {
    return new Promise((resolve) => window.setTimeout(resolve, ms));
  }

  /**
   * get file name from url
   * @param url url
   * @returns file name
   */
  public getFileNameFromUrl = (url: string): string | null => {
    try {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;
        const segments = pathname.split('/').filter(segment => !!segment);
        const lastSegment = segments[segments.length - 1];
        return lastSegment;
      } else {
        // Handle relative file paths
        const segments = url.split('/').filter(segment => !!segment);
        const lastSegment = segments[segments.length - 1];
        return lastSegment;
      }
    } catch (error) {
      return null; // Handle invalid URLs or exceptions
    }
  }
}

export const utils = new Utils();

