import * as React from "react";
import { withTranslation } from "react-i18next";
import { ExcelService } from "meekou-ts";
import i18n from "../i18n";
import { t } from "i18next";
import MeekouLayout from "../MeekouLayout";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Image, Label, makeStyles, Select } from "@fluentui/react-components";
import { LoginDto, LoginType, UserInfoDto } from "../models/mango.model";
import { mangoClient } from "../services/mangoClient";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setMessage } from "../redux/commonSlice";
import { refreshUserInfo, setAccessToken } from "../redux/userSlice";
import { MeekouConst } from "../models/meekou.const";
import { utilityService } from "meekou-ts";
import About from "./About";
import { AuthService } from "../services/auth.service";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../shared/authConfig";

const useStyles = makeStyles({
  loginImage: {
    width: '250px',
    height: '250px',
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const LanguageSettings = () => {
  const languages = [
    { key: "cn", text: t("cn") },
    { key: "en", text: t("en") },    
  ];
  const [lang, setLang] = React.useState(languages[0].key);
  React.useEffect(() => {
    const initSheets = async () => {
      // check i18n.language is not in languages
      if (languages.find(l => l.key === i18n.language)) {
        changeLanguage(i18n.language);
      }
      else{
        changeLanguage(lang);
      }
    };
    initSheets().catch(console.error);
  }, []);
  const changeLanguage = (lang) => {
    setLang(lang); 
    i18n.changeLanguage(lang); 
              
  };

  return (
    <Select value={lang} onChange={(_event, option) => changeLanguage(option.value)}>
      {
        languages.map((language) => (
          <option key={language.key} value={language.key}>{language.text}</option >
        ))
      }
    </Select>
  );
};
const Settings = () => {
  const styles = useStyles();
  const authService = new AuthService();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user.userInfo);
  const [isShowLogin, setIsShowLogin] = React.useState(false);
  const [loginQRCode, setLoginQRCode] = React.useState("");  
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const login = async () =>{
    setIsShowLogin(true);
    const loginDto = LoginDto.fromJS({
      loginType: LoginType.ScanMiniProgramQrCode,
      id: utilityService.newGuid()
    });
    let loginResult = await mangoClient.loginCenter(loginDto);
    if (!loginResult.isLogin && loginResult.miniQrCode) {
        setLoginQRCode(loginResult.miniQrCode);
        const loginStartTime = Date.now();
        while (!loginResult.isLogin) {
          const elapsedTime = Date.now() - loginStartTime;
          await utilityService.sleep(5000);
          loginResult = await mangoClient.loginCenter(loginDto);            
          if (elapsedTime >= 300000) { // 5 minutes
            setIsShowLogin(false);
            dispatch(setMessage(t("loginTimeout")));
            break;              
          }        
        }
    }
    if (loginResult.isLogin) {      
      dispatch(refreshUserInfo(loginResult.userInfoDto));
      dispatch(setAccessToken(loginResult.accessToken));
      localStorage.setItem(MeekouConst.userInfoKey, JSON.stringify(loginResult.userInfoDto));
      localStorage.setItem(MeekouConst.accessTokenKey, loginResult.accessToken);
      setIsShowLogin(false);
      dispatch(setMessage(t("loginSuccess")));
      setLoginQRCode("");
    }
  }
  const MicrosoftLogin = async () =>{    
    let loginResult = await mangoClient.getProfile();
    if(!(loginResult && loginResult.isLogin)){
      await authService.microsoftDialogLogin();
    }
    if (loginResult && loginResult.isLogin) {      
      dispatch(refreshUserInfo(loginResult.userInfoDto));
      localStorage.setItem(MeekouConst.userInfoKey, JSON.stringify(loginResult.userInfoDto));
      dispatch(setMessage(t("loginSuccess")));
    }
    else{
      dispatch(setMessage(t("loginFailed")));
    }
  }
  const LoginOut = async () =>{
    localStorage.removeItem(MeekouConst.userInfoKey);
    localStorage.removeItem(MeekouConst.accessTokenKey);
    dispatch(refreshUserInfo(UserInfoDto.fromJS({})));
    dispatch(setAccessToken(""));
  }
  const test = async () =>{
    var excelService = new ExcelService(null);
    dispatch(setMessage(await excelService.test() + i18n.language));
  }
  return (
    <MeekouLayout isReady>
      {/* <Button onClick={test}>Test</Button> */}
      {!(user &&  user.id) &&
        <div>
          <Button aria-expanded={isShowLogin} onClick={login}>
              {t("login")}
          </Button>
          <Button aria-expanded={isShowLogin} onClick={MicrosoftLogin}>
              {t("microsoftlogin")}
          </Button>
        </div>
      }
      {(user &&  user.id) &&
        <>
          <Label>{user.nickName || user.displayName}</Label>
          <Button onClick={LoginOut}>{t("loginOut")}</Button>          
        </>
      }
      <LanguageSettings />
      <About />
      <Dialog open={isShowLogin}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Dialog title</DialogTitle>
            <DialogContent>
              <Image src={loginQRCode} className={styles.loginImage} />
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </MeekouLayout>
  );
};

export default withTranslation()(Settings);


