import { t } from "i18next";
import { utilityService } from "meekou-ts";
import React, { useRef } from "react";
import { FileType } from "../../models/fileType";
import { utilsHelper } from "../../services/utilsHelper";
import styles from "./dataPicker.module.css";

interface IDataPickerProps {
  accept?: Array<string>;
  contentType?: FileType;
  multiple?: boolean;
  handler?: (files: FileList | string) => Promise<void>;
  children?: any;
}

const DataPicker = (props: IDataPickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const fileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      await props.handler(e.target.files);
    }
  };
  
  const handleDoubleClick = () => {
    inputRef?.current?.click();
  };

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.files) {
      e.preventDefault();
      e.stopPropagation();
      await props.handler(e.dataTransfer.files);
    }
  };

  const handlePaste = async (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (e.clipboardData.files && e.clipboardData.files.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      await props.handler(e.clipboardData.files);
    } else {
      const clipboardContents = await navigator.clipboard.read();
      if (props.contentType === FileType.JSON) {
        const blob = await clipboardContents[0].getType("text/plain");
        const content = await blob.text();
        props.handler(content);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.banner}
        onDoubleClick={handleDoubleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onPaste={handlePaste}
      >
        {props.children ? (
          props.children
        ) : (
          <>
            <span className={styles.bannerText}>{t("clickToPaste")}</span>
            <span className={styles.bannerText}>{t("or")}</span>
            <span className={styles.bannerText}>{t("doubleClickToAddFiles")}</span>
            <span className={styles.bannerText}>{t("or")}</span>
            <span className={styles.bannerText}>{t("dragAndDropFiles")}</span>
          </>
        )}
      </div>
      <input
        type="file"
        aria-label="add files"
        ref={inputRef}
        className={styles.input}
        multiple={props.multiple}
        onChange={fileChange}
        accept={props.accept?.join(",")}
      />
    </div>
  );
};

export default DataPicker;
