import * as React from "react";
import * as ReactDOM from "react-dom";
import { webLightTheme, FluentProvider } from "@fluentui/react-components";
import "./i18n";
import { t } from "i18next";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App, { AppProps } from "./App";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { AccountInfo, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./shared/authConfig";

/* global Office, */

let appProps: AppProps = {
  title: t("meekouAddIn"),
  isOfficeInitialized: false,
  hostType: Office.HostType.Excel
};
/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = (event.payload as AccountInfo);
        msalInstance.setActiveAccount(account);
    }
});
const render = (Component) => {
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}> 
        <FluentProvider theme={webLightTheme}>
          <Component {...appProps} />
        </FluentProvider>
      </Provider>
    </MsalProvider>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
(async () => {
  const status = await Office.onReady();
  if (status.host === Office.HostType.Excel || status.host === Office.HostType.Word) {
    appProps.isOfficeInitialized = true;
    appProps.hostType = status.host;
  }  
  render(App);
})();


if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}

