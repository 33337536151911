import { RootState, store } from './store'
import { clearMessage, setMessage } from './commonSlice'
import { useAppSelector } from './hooks';

import { refreshUserInfo, setAccessToken } from './userSlice';
import { mangoClient } from '../services/mangoClient';
import { observer } from 'redux-observers';
import { MeekouConst } from '../models/meekou.const';
import { UserInfoDto } from '../services/meekouapi';

export const userTriggerObserver = observer(
    (state: RootState) => state.common.userTrigger,
    async (dispatch, current, previous) => {
        const user = store.getState().user.userInfo;
        const userInStorage = JSON.parse(localStorage.getItem(MeekouConst.userInfoKey)) as UserInfoDto;
        const userId = user.id || userInStorage?.id;
        if (userId) {
            let newUserInfo = await mangoClient.getUser(userId);
            if (newUserInfo) {  
                localStorage.setItem(MeekouConst.userInfoKey, JSON.stringify(newUserInfo));              
                dispatch(refreshUserInfo(newUserInfo));         
            } else {
                localStorage.removeItem(MeekouConst.userInfoKey);
                localStorage.removeItem(MeekouConst.accessTokenKey);
                dispatch(refreshUserInfo(null));
            }
          }
    }
)

export const messageObserver = observer(
    (state: RootState) => state.common.message,
    async (dispatch, current, previous) => {
        if(current){
            setTimeout(function () {
               dispatch(clearMessage());
            }, 2000);
        }
    }
)