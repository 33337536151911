import { Spinner } from "@fluentui/react-components";
import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";

const LoadingDailog = () => {
    useEffect(() => {
        // Set the browser title when the component mounts
        document.title = 'Loading...';
    
        // Optionally, you can reset the title when the component unmounts
        return () => {
          document.title = 'Original Title';
        };
      }, []);
    
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Set the height to the full viewport height
          }}
        >
          <Spinner size="huge" />
        </div>
      );
  };

export default LoadingDailog;