/* global $ */

/**
 * convert table html string to array
 * @param tableHtmlString html string for table
 * @returns
 */
const convertTableToArray = (tableHtmlString: string): unknown[][] => {
  const result = [];
  const trList = $(tableHtmlString).find("tr");
  $.each(trList, function (key, el) {
    const tdList = $(el).find("td");
    const columns = [];
    $.each(tdList, function (key, td) {
      columns.push($(td).text());
    });
    result.push(columns);
  });
  return result;
};

/**
 * convert column number to letter
 * @param columnNumber column numer
 * @returns letter for column
 */
const convertColumnNumberToLetter = (columnNumber: number): string => {
  var ordA = "a".charCodeAt(0);
  var ordZ = "z".charCodeAt(0);
  var len = ordZ - ordA + 1;

  var columnLetter = "";
  while (columnNumber >= 0) {
    columnLetter = String.fromCharCode((columnNumber % len) + ordA) + columnLetter;
    columnNumber = Math.floor(columnNumber / len) - 1;
  }
  return columnLetter;
};

export { convertTableToArray, convertColumnNumberToLetter };
