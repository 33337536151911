import { Label } from "@fluentui/react-components";
import React from "react";

const About = () =>{
    return (
        <>
            <Label size="medium">Version:</Label>
            <Label size="medium">2024.3.5</Label>
        </>
    )
}

export default About;