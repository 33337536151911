import { Button, Input, Label } from "@fluentui/react-components";
import { t } from "i18next";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Image } from "image-js";
import MeekouLayout from "../../MeekouLayout";
import { setMessage } from "../../redux/commonSlice";
import { useAppDispatch } from "../../redux/hooks";

const ImageResize = () =>{
    const dispatch = useAppDispatch();
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [file, setFile] = useState(null);
    const [width, setWidth] = useState("0");
    const [heigth, setHeight] = useState("0");
    const fileChange = (file) =>{
        setFile(file);
    }
    const widthChange = (event, data) => {
        setWidth(data.value);
    };
    const heigthChange = (event, data) => {
        setHeight(data.value);
    };
    const imageConvert = async () =>{
        const reader = new FileReader();
        reader.onload = async (event) => {
            let image = await Image.load(event.target.result as any);
            let result = image
                .resize({ width: Number(width), height: Number(heigth) });
            
            document.getElementById('canvas').src = result.toDataURL();
        }
        reader.readAsDataURL(file);
    }
    return (
        <MeekouLayout>
            <FileUploader handleChange={fileChange} name="file" types={fileTypes} />
            <Label htmlFor="width">{t("EnterWidth")}</Label>
            <Input placeholder="This is a placeholder" id="width" type="number" value={width} onChange={widthChange}/>
            <Label htmlFor="height">{t("EnterHeight")}</Label>
            <Input placeholder="This is a placeholder" id="height" type="number" value={heigth} onChange={heigthChange}/>
            <Button onClick={imageConvert}>{t("Confirm")}</Button>
            <img id="canvas" width='55' height='55'></img>
        </MeekouLayout>
    )
}

export default ImageResize;