import { Button, Image, Tooltip } from "@fluentui/react-components";
import React, { useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { t } from "i18next";
import MeekouLayout from "../MeekouLayout"
import { withTranslation } from "react-i18next";
import axios from "axios";
import { saveAs } from 'file-saver';
import { MeekouConst } from "../models/meekou.const";
import { utilsHelper } from "../services/utilsHelper";
import { setMessage } from "../redux/commonSlice";
import { useAppDispatch } from "../redux/hooks";

const ImageRemoveBackGround = () => {
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [file, setFile] = useState<File>(null);
    const [fileName, setFileName] = useState(null);
    const [bgRemovedImg, setBgRemovedImg] = useState("");
    const fileChange = (file: File) =>{
        setFile(file);
    }
    const dispatch = useAppDispatch();
    const removeBackGround = async () =>{
        if (file) {
            const formData = new FormData();
            formData.append('img', file);
            try { 
                const response = await axios.post(`${MeekouConst.functionApiBaseUrl}/api/image/removebg`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Important for file uploads
                    },
                });   
                let fileName = file.name;    
                const bgRemovedImg = response.data;
                if (bgRemovedImg.imgName) {
                    fileName = bgRemovedImg.imgName
                }
                const imgDataUrI = `data:${bgRemovedImg.imgMiniType};base64,${bgRemovedImg.imageBase64}`;
                // utilsHelper.downloadDataURI(imgDataUrI, fileName); // disable auto download
                setFileName(fileName);
                setBgRemovedImg(imgDataUrI);                
            } catch (error) {
                dispatch(setMessage(t("ops")));
                console.error('Error uploading file:', error);
            }
          }
    }
    return (
        <MeekouLayout>
            <FileUploader handleChange={fileChange} name="file" types={fileTypes} />
            <Button onClick={removeBackGround}>{t("Confirm")}</Button>
            <Tooltip content={t("ClickToDownload")} relationship="description">
                <a href={bgRemovedImg} download={fileName}>
                    <Image src={bgRemovedImg}/>
                </a> 
            </Tooltip>           
        </MeekouLayout>
    )
}

export default withTranslation()(ImageRemoveBackGround);