import { configureStore } from "@reduxjs/toolkit";
import userReducer from './userSlice';
import commonSlice from "./commonSlice";

export const store = configureStore({
    reducer:{
        user: userReducer,
        common: commonSlice
    },
    // middleware: (getDefaultMiddleware) =>
    // getDefaultMiddleware().prepend(listenerMiddleware.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch