import { makeStyles, shorthands } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { t } from "i18next";
import React from "react";
import { Outlet } from "react-router-dom";
import Loading from "./controls/loading/loading";
import MeekouComingSoon from "./MeekouComingSoon";
import { MeekouConst } from "./models/meekou.const";
import { clearMessage } from "./redux/commonSlice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { AppConsts } from "./shared/appconsts";

export interface MeekouLayoutProps {
    children?: React.ReactNode;
    isReady?: boolean;
  }
  // layout for excel addin
const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("10px"),
      "> div": {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px"),
      },
    },
  });
  const MeekouLayout = (props: MeekouLayoutProps) => {
    const dispatch = useAppDispatch();
    const message = useAppSelector(state => state.common.message);
    const loadingState = useAppSelector(state => state.common.loadingState);
    const styles = useStyles();
    return (
      <div>
        <div>
            {
            message && 
            <Alert intent="warning" action="Dismiss" onClick={() => dispatch(clearMessage())}>
                {message}
            </Alert>
            }
        </div>
        <div className={styles.root}>
            {props.isReady || AppConsts.isDev ? props.children : <MeekouComingSoon />}
        </div>
        <div>
          <Loading shown={loadingState.shown} label={loadingState.label}></Loading>
        </div>
      </div>
    );
  };
  
  export default MeekouLayout;