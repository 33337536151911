import { Dropdown, IDropdownOption } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { t } from "i18next";
import { utilityService } from "meekou-ts";
import React from "react";
import { withTranslation } from "react-i18next";
import { FileType } from "../../models/fileType";
import { excelHelper } from "../../services/excel.helper";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import DataPicker from "../../controls/dataPicker/dataPicker";

const Import = () => {
  const [importArea, setImportArea] = React.useState("");
  const [importType, setImportType] = React.useState(FileType.JSON);
  const [data, setData] = React.useState([]);
  const importOptions = [
    { key: FileType.JSON, text: t(FileType.JSON) }
  ];
  const areaSelectOnChange = (address: any, values: any) =>{
    setImportArea(address);
  }
  const importTypeChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>) => {
    setImportType(option.key as FileType);
  };
  /**
   * import data
   * @param fileType
   * @param fileContent
   */
  const importData = async () =>{
    if (data) {
      await excelHelper.setRangeValue(importArea, data);
    }
  }
  const importHandler = async (files) => {
    const dataString = await utilityService.readTextFile(files[0]);
    const data = await utilityService.jsonStringToArray(dataString);
    setData(data);
  };
  return (<div>
    <Dropdown
      label={t("importType")}
      defaultSelectedKey={importType}
      options={importOptions}
      onChange={importTypeChange}
    />
    <AreaSelect
      title={t("selectArea")}
      selectedAddress={importArea}
      onChange={areaSelectOnChange}
    />
    <DataPicker accept={["json/*"]} handler={importHandler} contentType={importType}>

    </DataPicker>
    <Button onClick={importData}>{t("import")}</Button>
  </div>);
};

export default withTranslation()(Import);
