import { withTranslation } from "react-i18next";
import * as React from "react";
import { t } from "i18next";
import { Button, Label, makeStyles, Textarea, Dropdown, Option } from "@fluentui/react-components";
import { AddCircle24Regular, Delete24Regular } from "@fluentui/react-icons";
import AccessControl from "../../controls/accessControlComponent/accessControl";

/* global Excel  */
interface ColumnSelectProp {
  Id: number;
  Columns: string[];
  onSelectColumnChange: (filterColumn: FilterColumn) => void;
  onAddBatchFilter: () => void;
  onRemoveBatchFilter: (newFilterColumn: FilterColumn) => void;
  DisableNew?: boolean;
}
interface FilterColumn {
  Id?: number;
  ColumnName?: string;
  Filters?: string[];
  DisableNew?: boolean;
}
const useStyles = makeStyles({
  filtersClass: {
    width: "250px",
    height: "150px",
  },
});

const ColumnSelect = (props: ColumnSelectProp) => {
  const styles = useStyles();
  const [columnFilter, setColumnFilter] = React.useState(props);
  const onSelectColumnChange = (event, data) => {
    let newSelectColumn: FilterColumn = {
      Id: props.Id,
      ColumnName: data.selectedOptions[0],
    };
    updateSelectColumn(newSelectColumn);
  };
  const updateSelectColumn = (newSelectColumn: FilterColumn) => {
    let updatedColumnFilter = { ...columnFilter, ...newSelectColumn };
    setColumnFilter(updatedColumnFilter);
    props.onSelectColumnChange(updatedColumnFilter);
  };
  const onFilterTextChange = (ev, data) => {
    let newSelectColumn: FilterColumn = {
      Id: props.Id,
      Filters: data.value.split(/\r?\n/).filter((f) => f),
    };
    updateSelectColumn(newSelectColumn);
  };
  return (
    <div key={props.Id}>
      <div>
        <Dropdown placeholder={t("SelectColumn")} onOptionSelect={onSelectColumnChange}>
          {props.Columns.map((column) => (
            <Option key={column}>{column}</Option>
          ))}
        </Dropdown>
        {!props.DisableNew && (
          <Button icon={<AddCircle24Regular />} iconPosition="after" onClick={props.onAddBatchFilter}></Button>
        )}
        <Button
          icon={<Delete24Regular />}
          iconPosition="after"
          onClick={() => props.onRemoveBatchFilter({ Id: props.Id })}
        ></Button>
      </div>
      <div>
        <Textarea resize="both" textarea={{ className: styles.filtersClass }} onChange={onFilterTextChange} />
      </div>
    </div>
  );
};
const BatchFilter = () => {
  const initalColumns: string[] = [];
  const [columns, setColumns] = React.useState(initalColumns);
  const initFilterColumns: FilterColumn = { Id: 0 };
  const [filterColumns, setFilterColumns] = React.useState([initFilterColumns]);
  React.useEffect(() => {
    const initColumns = async () => {
      let items: string[] = [];
      await Excel.run(async (context) => {
        // only get first row for column name to avoid load entire sheet
        let usedRange = context.workbook.worksheets.getActiveWorksheet().getUsedRange().getRow(0);
        usedRange.load("values");
        await context.sync();
        usedRange.values[0].map((columnName, index) => {
          items.push(columnName);
        });
      });

      setColumns(items);
    };
    initColumns().catch(console.error);
  }, []);
  const onSelectColumnChange = (newFilterColumn: FilterColumn) => {
    const newFilterColumns = filterColumns.map((filterColumn: FilterColumn) => {
      if (filterColumn.Id == newFilterColumn.Id) {
        filterColumn.ColumnName = newFilterColumn.ColumnName;
        filterColumn.Filters = newFilterColumn.Filters;
      }
      return filterColumn;
    });
    setFilterColumns(newFilterColumns);
  };
  const onAddBatchFilter = () => {
    const newFilterColumns = filterColumns.map((filterColumn: FilterColumn) => {
      filterColumn.DisableNew = true;
      return filterColumn;
    });
    newFilterColumns.push({ Id: newFilterColumns.length, DisableNew: filterColumns.length > columns.length - 2 });
    setFilterColumns(newFilterColumns);
  };
  const onRemoveBatchFilter = (newFilterColumn: FilterColumn) => {
    const newFilterColumns = filterColumns
      .filter((f) => f.Id != newFilterColumn.Id)
      .map((filterColumn: FilterColumn) => {
        filterColumn.DisableNew = false;
        return filterColumn;
      });
    setFilterColumns(newFilterColumns);
  };
  const onBatchFilter = async () => {
    await Excel.run(async (context) => {
      var sheet = context.workbook.worksheets.getActiveWorksheet();
      var range = sheet.getUsedRange();
      filterColumns.forEach((filterColumn) => {
        sheet.autoFilter.apply(
          range,
          columns.findIndex((c) => c == filterColumn.ColumnName),
          {
            filterOn: Excel.FilterOn.values,
            values: filterColumn.Filters,
          }
        );
      });
      await context.sync();
    });
  };
  let children = (
    <div>
      <div>
        {filterColumns.map((filterColumnName, index) => (
          <ColumnSelect
            key={index}
            Id={index}
            Columns={columns.filter((c) => !filterColumns.find((f) => f && f.ColumnName === c))}
            onSelectColumnChange={onSelectColumnChange}
            onAddBatchFilter={onAddBatchFilter}
            onRemoveBatchFilter={onRemoveBatchFilter}
            DisableNew={filterColumnName.DisableNew}
          ></ColumnSelect>
        ))}
      </div>
      <div>
        <Button onClick={onBatchFilter}>{t("confirm")}</Button>
      </div>
    </div>
  );
  return <AccessControl children={children}></AccessControl>;
  // return <AccessControl requiredProduct="Excel.BatchFilter" children={children}></AccessControl>;
};
export default withTranslation()(BatchFilter);
