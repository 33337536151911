import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserInfoDto } from "../models/mango.model";
import { MeekouConst } from "../models/meekou.const";
import { RootState } from "./store";

interface UserState {
    accessToken: string;
    userInfo: UserInfoDto;
}
const initialState: UserState = {
    accessToken: "",
    userInfo: localStorage.getItem(MeekouConst.userInfoKey) == null ? null : UserInfoDto.fromJS(JSON.parse(localStorage.getItem(MeekouConst.userInfoKey)))
}
export const userSlice = createSlice({
    name: 'user',
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload
        },
        refreshUserInfo: (state, action: PayloadAction<UserInfoDto>) =>{
            state.userInfo = {...state.userInfo, ...action.payload};
        },
    },
    initialState: initialState
})

export const { setAccessToken, refreshUserInfo } = userSlice.actions

export default userSlice.reducer;

export const selectAccessToken = (state: RootState) => state.user.accessToken