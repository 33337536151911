
import axios, { AxiosInstance } from 'axios';
import { AddIntegralsToUserInput, BlogDto, BlogPagedRequestDto, BlogPagedResponse, IntegralType, LoginDto, LoginType, MeekouPagedResponse, MeekouResponse, ProductDto, ProductPagedRequestDto, ProductPagedResponse, RenewUserProductInput, UserInfoDto, UserProductDto } from '../models/mango.model';
import { MeekouConst } from '../models/meekou.const';
import { AppConsts } from '../shared/appconsts';
import { axiosRequestOnFulfilled, axiosResponseOnRejected } from './axiosClient';

class MangoClient {
    private static instance: MangoClient;
    client: AxiosInstance;
    baseUrl = AppConsts.remoteServiceBaseUrl;
    /**
     *
     */
    constructor() {
        this.client = axios.create();
    }
    public static getInstance(): MangoClient {
        if (!MangoClient.instance) {
            MangoClient.instance = new MangoClient();
        }
        return MangoClient.instance;
    }
    configClient(clientOnFulfilled, clientResponseonRejected){
        this.client.interceptors.request.use(clientOnFulfilled,error => {
            Promise.reject(error)
        });
        this.client.interceptors.response.use((response) => {
            return response
        }, clientResponseonRejected);
    }
    /**
     * @param loginDto (optional) 
     * @return Success
     */
    async loginCenter(loginDto: LoginDto) : Promise<LoginDto | undefined> {
        let url_ = this.baseUrl + "/api/services/app/User/LoginCenter";
        url_ = url_.replace(/[?&]$/, "");
        let {data: loginResult} = await this.client.post<MeekouResponse<LoginDto>>(url_, loginDto);
        return loginResult.success ? loginResult.result : undefined;
    }  
    /**
     * get user info by id
     * @param userId user id
     * @returns user info
     */
    async getUser(userId: number): Promise<UserInfoDto | undefined> {
        let url_ = this.baseUrl + "/api/services/app/User/GetUserInfoById?";
        url_ += "userId=" + encodeURIComponent("" + userId) + "&"; 
        url_ = url_.replace(/[?&]$/, "");
        let {data: userInfo} = await this.client.get<MeekouResponse<UserInfoDto>>(url_);
        return userInfo.success ? userInfo.result : undefined;
    } 
    /**
     * 签到
     */
    async signIn(userId: number): Promise<UserInfoDto | undefined>{
        let signInResult = await this.changeIntegrals(AddIntegralsToUserInput.fromJS({
            name: MeekouConst.signInKey,
            integral: MeekouConst.signIntegral,
            integralType: IntegralType[IntegralType.SignIn],
            ownerId: userId,
        }));
        return signInResult;
    }
     /**
     * 看广告赚米粒
     * @param userDetails 
     * @returns 
     */
     async viewAds(userId: number): Promise<UserInfoDto | undefined>{
        let viewAdsResult = await this.changeIntegrals(AddIntegralsToUserInput.fromJS({
            name: MeekouConst.viewAdsKey,
            integral: MeekouConst.viewAdsIntegral,
            integralType: IntegralType[IntegralType.ViewRewardedAds],
            ownerId: userId,
        }));
        return viewAdsResult;
    }
    /**
     * 消耗米粒购买资源
     * @param userDetails 
     * @param blog 
     * @returns 
     */
    async buyResources(userId: number,  blog: BlogDto): Promise<UserInfoDto | undefined>{
        let buyResourcesResult = await this.changeIntegrals(AddIntegralsToUserInput.fromJS({
            name: MeekouConst.buyResourcesKey,
            integral: - blog.integral,
            integralType: IntegralType[IntegralType.BuyResources],
            ownerId: userId,
        }));
        return buyResourcesResult;
    }
    /**
     * add integrals to user
     * @param body 
     * @returns 
     */
    async changeIntegrals(body: AddIntegralsToUserInput): Promise<UserInfoDto | undefined> {
        let url = this.baseUrl + "/api/services/app/User/ChangeIntegrals";
        url = url.replace(/[?&]$/, "");
        let {data: changeIntegralsResult} = await this.client.post<MeekouResponse<UserInfoDto>>(url, body);
        return changeIntegralsResult.success ? changeIntegralsResult.result : undefined;
    }
    //#region Blog
    async getBlogs(input: BlogPagedRequestDto): Promise<MeekouPagedResponse<BlogDto> | undefined> {
        let url = `${ this.baseUrl }/api/services/app/Blog/GetAll?IsMiniProgramEnabled=true&`
        if (input.filter) {
            url += "Filter=" + encodeURIComponent("" + input.filter) + "&";
        }
        if (input.sorting) {
            url += "Sorting=" + encodeURIComponent("" + input.sorting) + "&";
        }
        if (input.skipCount) {
            url += "SkipCount=" + encodeURIComponent("" + input.skipCount) + "&";
        }
        if (input.maxResultCount) {
            url += "MaxResultCount=" + encodeURIComponent("" + input.maxResultCount) + "&";
        }
        url = url.replace(/[?&]$/, "");
        let {data :blogs} = await this.client.get<MeekouResponse<MeekouPagedResponse<BlogDto>>>(url);
        return blogs.success ? blogs.result : undefined;
    }
    //#endregion
    //#region Product
    /**
     * get product info by id
     * @param id id for product
     * @returns product dto
     */
    async getProduct(id: number): Promise<ProductDto | undefined> {
        let url_ = this.baseUrl + "/api/services/app/Product/Get?";
        if (id === null)
            throw new Error("The parameter 'id' cannot be null.");
        else if (id !== undefined)
            url_ += "Id=" + encodeURIComponent("" + id) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let {data: product} = await this.client.get<MeekouResponse<ProductDto>>(url_);
        return product.success ? product.result : undefined; 
    }
    /**
     * get paged product list
     * @param input request for get all products
     * @returns paged product
     */
    async getProducts(input: ProductPagedRequestDto): Promise<MeekouPagedResponse<ProductDto> | undefined> {
        let url = `${ this.baseUrl }/api/services/app/Product/GetAll?`
        if (input.filter) {
            url += "Filter=" + encodeURIComponent("" + input.filter) + "&";
        }
        if (input.sorting) {
            url += "Sorting=" + encodeURIComponent("" + input.sorting) + "&";
        }
        if (input.skipCount) {
            url += "SkipCount=" + encodeURIComponent("" + input.skipCount) + "&";
        }
        if (input.maxResultCount) {
            url += "MaxResultCount=" + encodeURIComponent("" + input.maxResultCount) + "&";
        }
        url = url.replace(/[?&]$/, "");
        let {data: products} = await this.client.get<MeekouResponse<MeekouPagedResponse<ProductDto>>>(url);
        return products.success ? products.result : undefined; 
    }
    /**
     * 
     * @param body renew user product input
     * @returns user product
     */
    async renewProduct(body: RenewUserProductInput | undefined) : Promise<UserProductDto | undefined> {
        let url_ = this.baseUrl + "/api/services/app/User/RenewProduct";
        url_ = url_.replace(/[?&]$/, "");
        const { data : result} = await this.client.post<MeekouResponse<UserProductDto>>(url_, body);
        return result.success ? result.result : undefined;
    }
    //#endregion
    //#region Notion
    async getNotionContentByBlockId(): Promise<string | undefined> {
        let url = this.baseUrl + "/api/services/app/Notion/GetNotionContentByBlockId?";
        let {data: content} = await this.client.get<MeekouResponse<string>>(url);
        return content.success ? content.result : undefined;
    }
    //#endregion

    //#region Function Api
    async getProfile(): Promise<LoginDto>{
        let url_ = `${AppConsts.functionApiBaseUrl}/api/profile`;
        url_ = url_.replace(/[?&]$/, ""); 
        let {data: loginResult} = await this.client.get<MeekouResponse<LoginDto>>(url_);
        if (!loginResult.success) {
            console.log(loginResult.error);          
        }
        return loginResult.success ? loginResult.result : undefined;
    }
    //#endregion
}
const instance = MangoClient.getInstance();
instance.configClient(axiosRequestOnFulfilled, axiosResponseOnRejected);
export const mangoClient = instance;