import * as React from "react";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import { Button } from "@fluentui/react-components";
import { utilsHelper } from "../../services/utilsHelper";
import { excelHelper } from "../../services/excel.helper";
import { Alert } from "@fluentui/react-components/unstable";
import MeekouLayout from "../../MeekouLayout";
import { setMessage } from "../../redux/commonSlice";
import { useAppDispatch } from "../../redux/hooks";
import AccessControl from "../../controls/accessControlComponent/accessControl";
/* global Excel */

const PasteToVisiable = () => {
  const dispatch = useAppDispatch();
  const [startCell, setstartCellState] = React.useState("");
  const areaSelectOnChange = React.useCallback((cell) => {
    setstartCellState(cell);
  }, []);
  const handlePaste = async () => {
    try {
      const clipboardData = await utilsHelper.getDataFromClipboard();
      if (!clipboardData || clipboardData.length === 0) {
        dispatch(setMessage(t("NoDataInClipboard")));
        return;
      }
      await excelHelper.writeArrayToVisibleRange(startCell,clipboardData);
    } catch (error) {
      dispatch(setMessage(t(error.message)));
    }
  };
  return (
    <MeekouLayout>
      <AccessControl>
        <AreaSelect selectedAddress={startCell} onChange={areaSelectOnChange} />
        <Button onClick={handlePaste}>{t("confirm")}</Button>
      </AccessControl>
    </MeekouLayout>
  );
};

export default withTranslation()(PasteToVisiable);
