import { Label } from "@fluentui/react";
import { Button, Checkbox, CheckboxProps, Input } from "@fluentui/react-components";
import { t } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import AreaSelect from "../../controls/areaSelect/areaSelect";
import MeekouLayout from "../../MeekouLayout";
import { excelHelper } from "../../services/excel.helper";

const RangeEditForm = () =>{  
    const [rangeArea, setRangeArea] = React.useState("");
    const [rangeData, setRangeData] = React.useState([]);
    const [rangeHasHeader, setRangeHasHeader] = React.useState<CheckboxProps["checked"]>(true);
    const [formLabel, setFormLabel] = React.useState([]);

    const areaSelectOnChange = React.useCallback((address, values) => {
        setRangeArea(address);
        setRangeData(values);
      }, []);

    const createForm = async () =>{
        console.log("createForm");  
        // listener for row change
        await Excel.run(async (context) => {
            let workbook = context.workbook;
            let eventResult = workbook.onSelectionChanged.add(() => rowChange());
            await context.sync();
        });  
        await buildForm(rangeHasHeader ? 1 : 0);
    }
    const buildForm =async (rowIndex) => {
        const [worksheetName, rangeAddress] = rangeArea.split(/!(.*)/);
        await Excel.run(async (context) => {
            let sheet = context.workbook.worksheets.getItem(worksheetName); 
            let range = sheet.getRange(rangeAddress);
            range.load("rowIndex,columnCount");
            await context.sync();
            const columnsCount = range.columnCount;
            const currentForm = []
            for (let index = 0; index < columnsCount; index++) {
                let cell = range.getCell(rowIndex, index);
                cell.load("rowIndex,columnIndex,values,valuesAsJson");
                await context.sync();
                const columnLetter = excelHelper.columnIndexToLetter(cell.columnIndex);
                currentForm.push({
                    index: index,
                    rowIndex: cell.rowIndex,
                    columnIndex: cell.columnIndex,
                    controlLabel: rangeHasHeader ? rangeData[0][index] : columnLetter,
                    controlType: cell.valuesAsJson[0][0].type,
                    controlValue: cell.values[0][0]
                });
            }
            console.log(currentForm);
            setFormLabel(currentForm);
        });
    }
    const rowChange = async () =>{
        await Excel.run(async (context) => {
            const [worksheetName, rangeAddress] = rangeArea.split(/!(.*)/);
            let sheet = context.workbook.worksheets.getItem(worksheetName); 
            let range = sheet.getRange(rangeAddress);
            range.load("rowIndex,columnCount");
            let workbook = context.workbook;
            let selection = workbook.getSelectedRange();            
            selection.load("address,rowIndex,values");
            await context.sync();            
            const isInRange = await excelHelper.isInRange(selection.address, rangeArea);
            if (isInRange) {                
                await buildForm(selection.rowIndex - range.rowIndex);
            }
        });
    }
    const fieldOnChange = async (event, value, field) =>{
        event.preventDefault();
        const currentRow = [...formLabel];
        currentRow[field.index].controlValue = value;
        setFormLabel(currentRow);
        // update cell value
        await Excel.run(async (context) =>{
            const [worksheetName, rangeAddress] = rangeArea.split(/!(.*)/);
            let sheet = context.workbook.worksheets.getItem(worksheetName); 
            let range = sheet.getCell(field.rowIndex, field.columnIndex);
            range.values = [[value]];
            await context.sync();
        });        
    }

    return (
        <MeekouLayout>
            <Checkbox checked={rangeHasHeader} onChange={(ev, data) => setRangeHasHeader(data.checked)} label={t("rangeHasHeader")}/>
            <AreaSelect
                title={t("selectArea")}
                selectedAddress={rangeArea}
                selectedValues={rangeData}
                onChange={areaSelectOnChange}
            />
            <Button onClick={createForm}>{t("createForm")}</Button> 
            <div>
                {
                    formLabel.map((field, index) => (
                        <div key={index}>
                            <Label htmlFor={field.controlLabel}>{field.controlLabel}</Label>
                            <Input 
                                type={field.controlType}
                                id={field.controlLabel}
                                name={field.controlLabel}
                                value={field.controlValue}
                                onChange={(event, data) => fieldOnChange(event, data.value, field)}
                            />
                        </div>
                        ))
                }  
            </div> 
        </MeekouLayout>
    )
}

export default withTranslation()(RangeEditForm);