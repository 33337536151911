import { withTranslation } from "react-i18next";
import * as React from "react";
import { t } from "i18next";
import { Button, ColorPicker, getColorFromString, IColor } from "@fluentui/react";
import { Radio, RadioGroup } from "@fluentui/react-components";
import { convertColumnNumberToLetter } from "../../services/html.helper";
/* global Excel  */

const Spotlight = () => {
  const Meekou: string = "Meekou";
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [applyArea, setApplyArea] = React.useState("applySelection");
  const white = getColorFromString("#ffffff")!;
  const [color, setColor] = React.useState(white);
  const [spotlightEventResult, setSpotlightEventResult] = React.useState(null);
  const updateColor = React.useCallback((ev: any, colorObj: IColor) => setColor(colorObj), []);
  const onApplyChange = React.useCallback((ev: any, apply) => setApplyArea(apply.value), []);
  const onSpotlightChange = async () => {
    setIsEnabled(!isEnabled);
    console.log(isEnabled);
    if (!isEnabled) {
      await Excel.run(async (context) => {
        let workbook = context.workbook;
        let eventResult = workbook.onSelectionChanged.add(() => spotlightHandler());
        setSpotlightEventResult(eventResult);
        await context.sync();
      });
    } else {
      await clearSpotlightRules();
      await Excel.run(spotlightEventResult.context, async (context: any) => {
        spotlightEventResult.remove();
        await context.sync();
        setSpotlightEventResult(null);
      });
    }
  };
  const spotlightHandler = async () => {
    await Excel.run(async (context) => {
      let workbook = context.workbook;
      let sheets = workbook.worksheets;
      sheets.load("items");
      await context.sync();
      // clear previous meekou conditional format
      await clearSpotlightRules();
      // add new conditional format
      let selection = workbook.getSelectedRange();
      selection.load("rowIndex,columnIndex,rowCount,columnCount");
      await context.sync();
      let rowConditionalRange = selection;
      let columnConditionalRange = selection;
      console.log(applyArea);
      if (applyArea == "applyRowAndColumn") {
        rowConditionalRange = selection.worksheet.getRange(
          `${selection.rowIndex + 1}:${selection.rowIndex + selection.rowCount}`
        );
        columnConditionalRange = selection.worksheet.getRange(
          `${convertColumnNumberToLetter(selection.columnIndex)}:${convertColumnNumberToLetter(
            selection.columnIndex + selection.columnCount - 1
          )}`
        );
      }
      await context.sync();

      let rowConditionalFormat = rowConditionalRange.conditionalFormats.add(Excel.ConditionalFormatType.custom);
      rowConditionalFormat.custom.format.fill.color = color.str;
      rowConditionalFormat.custom.rule.formula = `=AND(ROW() >= ${selection.rowIndex}, ROW() <= ${
        selection.rowIndex + selection.rowCount
      }) + N("${Meekou}")`;
      let columnConditionalFormat = columnConditionalRange.conditionalFormats.add(Excel.ConditionalFormatType.custom);
      columnConditionalFormat.custom.format.fill.color = color.str;
      columnConditionalFormat.custom.rule.formula = `=AND(Column() >= ${selection.columnIndex}, Column() <= ${
        selection.columnIndex + selection.columnCount
      }) + N("${Meekou}")`;
      await context.sync().catch((e) => console.log(e));
    });
  };
  const clearSpotlightRules = async () => {
    await Excel.run(async function (context) {
      let workbook = context.workbook;
      let worksheets = workbook.worksheets;
      worksheets.load("items/name");
      await context.sync();
      for (let i = 0; i < worksheets.items.length; i++) {
        let worksheet = worksheets.items[i];
        let conditionalFormats = worksheet.getRange().conditionalFormats;
        conditionalFormats.load("items/type");
        await context.sync();
        let total = conditionalFormats.items.length;
        for (let j = total - 1; j >= 0; j--) {
          let conditionalFormat = conditionalFormats.items[j];
          if (conditionalFormat.type == Excel.ConditionalFormatType.custom) {
            conditionalFormat.load("custom/rule/formula");
            await context.sync();
            if (conditionalFormat.custom.rule.formula && conditionalFormat.custom.rule.formula.includes(Meekou)) {
              conditionalFormat.delete();
            }
          }
        }
      }
      await context.sync();
    });
  };
  return (
    <div>
      <div>
        <RadioGroup onChange={onApplyChange} value={applyArea}>
          <Radio label={t("applySelection")} value="applySelection" />
          <Radio label={t("applyRowAndColumn")} value="applyRowAndColumn" />
        </RadioGroup>
      </div>
      <div>
        <ColorPicker onChange={updateColor} color={color} alphaType="none" showPreview={true} />
      </div>
      <div>
        <Button onClick={onSpotlightChange}>{!isEnabled ? t("on") : t("off")}</Button>
      </div>
    </div>
  );
};

export default withTranslation()(Spotlight);
