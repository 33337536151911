import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { AppProps } from "../App";
import { messageObserver } from "../redux/observers";
import { store } from "../redux/store";
import LoadingDailog from "./dialogs/LoadingDailog";

const DialogApp = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);

    return (
        <HashRouter basename="dialog">
            <Routes>   
                <Route path="loading" element={<LoadingDailog />} />                          
            </Routes>
        </HashRouter>
    );
  };

export default DialogApp;