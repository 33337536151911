import * as React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationCN from "./locales/cn/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  cn: {
    translation: translationCN,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "cn",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
