import axios from 'axios';
import { MeekouConst } from '../models/meekou.const';
import { setMessage } from '../redux/commonSlice';
import { store } from '../redux/store';
import { refreshUserInfo, setAccessToken } from '../redux/userSlice';
import { mangoClient } from './mangoClient';

export const axiosRequestOnFulfilled = async config => {
    const accessToken = store.getState().user.accessToken;
    const officeToken = await OfficeRuntime.auth.getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      //forMSGraphAccess: true,
    });
    config.headers = { 
      'Authorization': `Bearer ${accessToken}`,
      'OfficeToken': `${officeToken}`
    }
    return config;
};

export const axiosResponseOnRejected = async function (error) {
    const originalRequest = error.config;
    console.log(error);
    // if (error.response.status === 401 && !originalRequest._retry) {
    //     originalRequest._retry = true;
    //     const loginResult = await mangoClient.loginByMiniProgram();   
    //     if (loginResult) {
    //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginResult.accessToken;
    //         if (loginResult.accessToken) {
    //             store.dispatch(setAccessToken(loginResult.accessToken));
    //             store.dispatch(refreshUserInfo(loginResult.userInfoDto));
    //         }              
    //         return mangoClient.client(originalRequest);
    //     }   
    //     return mangoClient.client(originalRequest);   
    // }
    store.dispatch(setMessage("Ops,出错了!"))
    return Promise.reject(error);
}