import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { observe } from "redux-observers";
import { AppProps } from "../App";
import { messageObserver } from "../redux/observers";
import { store } from "../redux/store";
import Home from "./Home";

const HomeApp = (props: AppProps) => {
    React.useEffect(() => {
      observe(store, [messageObserver])
    }, []);

    return (
        <>
            {
                !window.location.hash.startsWith("#") &&
                <BrowserRouter>
                    <Routes>
                    <Route path="" element={<Home />} />
                    </Routes>          
                </BrowserRouter>
            }
        </>
    );
  };

export default HomeApp;