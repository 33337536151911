import { Button } from "@fluentui/react-components";
import * as React from "react";
import MeekouLayout from "../../MeekouLayout";
import { Sheet } from "../../models/excel.models";
import { makeStyles } from "../../theme";

/* global Excel  */
interface Props {
  tabColor: string;
  name: string;
  key: string;
  onClick: () => void;
}
const useStyles = makeStyles<{ props: Props }>()((_theme, { props }) => ({
  sheetClass: {
    color: props.tabColor,
  },
}));
export const MeekouButton = (props: Props) => {
  let { classes } = useStyles({ props });
  return (
    <div>
      <Button onClick={props.onClick} className={classes.sheetClass} {...props}>
        {props.name}
      </Button>
    </div>
  );
};
const Toc = () => {
  const [sheets, setState] = React.useState([]);
  React.useEffect(() => {
    const initSheets = async () => {
      let items: Sheet[] = [];
      await Excel.run(async (context) => {
        let sheets: Excel.WorksheetCollection = context.workbook.worksheets;
        sheets.load("items/name, items/tabColor");
        await context.sync();
        sheets.items.forEach((s) => {
          items.push({
            name: s.name,
            tabColor: s.tabColor,
          });
        });
      });
      setState(items);
    };
    initSheets().catch(console.error);
  }, []);
  const openSheet = React.useCallback(async (sheetName) => {
    await Excel.run(async (context) => {
      let sheet = context.workbook.worksheets.getItem(sheetName);
      sheet.activate();
      await context.sync();
    });
  }, []);
  return (
    <MeekouLayout isReady>
      {sheets.map((sheet: Sheet) => {
        return (
          <MeekouButton
            onClick={() => openSheet(sheet.name)}
            tabColor={sheet.tabColor}
            name={sheet.name}
            key={sheet.name}
          ></MeekouButton>
        );
      })}
    </MeekouLayout>
  );
};

export default Toc;
