import { Link } from "@fluentui/react";
import React, { MouseEventHandler } from "react";
import { saveAs } from 'file-saver';
import { utils } from "../../shared/utils";

interface DownloadAbleLinkOptions {
    href: string,
    node: any
}

const DownloadAbleLink = (props: DownloadAbleLinkOptions) =>{
    const downloadFiles = ["米可插件"]
    const [fileName, setFileName] = React.useState((props.node as any).children[0].value);
    const [fileUrl, setFileUrl] = React.useState(props.href);
    const downloadLink = (event) =>{
        if (downloadFiles.includes(fileName)) {
            event.preventDefault();
            saveAs(fileUrl, utils.getFileNameFromUrl(fileUrl));           
        }
    }
    return (
        <>
            <Link href={fileUrl} onClick={downloadLink}>{fileName}</Link>
        </>
    )
}

export default DownloadAbleLink;